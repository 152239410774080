import React from 'react';
import { Navigate } from 'react-router-dom';
import UserData from '../utils/UserData';
import { useIsAuthenticated } from '@azure/msal-react';
import PrefixPath from 'config/AppConfig';
import PropTypes from 'prop-types'; // Import PropTypes

function PublicRoute({ element }) {
  const { getUserLoginToken } = UserData();
  let isToken = useIsAuthenticated();
  const isAuthenticated = getUserLoginToken();
  return isToken && isAuthenticated ? (
    <Navigate to={`${PrefixPath.APP_PREFIX_PATH}/dashboard`} />
  ) : (
    element
  );
}

PublicRoute.propTypes = {
  element: PropTypes.node.isRequired, // 'element' should be a node (renderable JSX)
};

export default PublicRoute;
