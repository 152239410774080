import Constant from '../../Constants/Constants';
  
  const initialState = {
    title: "",
    actions: [],
  };
 class Headers{
    static HeadersReducer = (state = initialState, action) => {
        switch (action.type) {
            case Constant.SET_HEADER:
                return { ...state, ...action.payload };
              case Constant.RESET_HEADER:
                return initialState;
            default:
                return state;
            }
          };
  }
  export default Headers;