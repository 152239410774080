const { Spin } = require("antd")
import PropTypes from 'prop-types';
const SectionSpin = ({className,spinning,children})=>{
    return(
        <>
        <Spin className={className} spinning={spinning}>
{children}
        </Spin>
        </>
    )
}
SectionSpin.propTypes = {
    className: PropTypes.string,
    spinning: PropTypes.bool,
    children: PropTypes.node.isRequired,
  };
export default SectionSpin;