/* eslint-disable */
import { ReactElement, useState, useEffect } from "react";
import { Spin, Table, Modal, message } from 'antd';
import { ColumnsType } from "antd/es/table";
import { useStore } from "../../../stores/root-store-context";
import FileHelper from "../helpers/FileHelper";
import { getMimeImageType } from "../../ModalFileViewer/ModalFileViewer";
import Spinner from "Components/Spinner/Spiner";

const LotDocuments = (): ReactElement => {
    const { ricStore } = useStore();
    const [isModalVisible, setIsModalVisible] = useState(false);
    const [fileData, setFileData] = useState<Blob | null>(null);
    const [isLoadingLotDocs, setIsLoadingLotDocs] = useState<boolean>(false);
    const [isLoadingFile, setIsLoadingFile] = useState<boolean>(false);

    // Date formatting function
    const formatDate = (dateString: string) => {
        const date = new Date(dateString);
        return new Intl.DateTimeFormat('en-US').format(date); 
    };

    const columns: ColumnsType = [
        {
            title: "Name",
            dataIndex: "localName",
            key: "localName",
            sorter: (a, b) => a.localName.localeCompare(b.localName), // Sort by name
            sortDirections: ['ascend', 'descend'], // Sort icon
        },
        {
            title: "Doc Type",
            dataIndex: "documentType",
            key: "documentType",
            sorter: (a, b) => a.documentType.localeCompare(b.documentType), // Sort by doc type
            sortDirections: ['ascend', 'descend'], // Sort icon
        },
        {
            title: "Date",
            dataIndex: "originalDocumentDate",
            key: "originalDocumentDate",
            sorter: (a, b) => new Date(a.originalDocumentDate).getTime() - new Date(b.originalDocumentDate).getTime(), // Sort by date
            render: (text: string) => formatDate(text),
            sortDirections: ['ascend', 'descend'], // Sort icon
        },
        {
            title: "Address",
            dataIndex: "address",
            key: "address",
            sorter: (a, b) => a.address.localeCompare(b.address), // Sort by address
            sortDirections: ['ascend', 'descend'], // Sort icon
        },
        {
            title: "Resident Account",
            dataIndex: "residentAccount",
            key: "residentAccount",
            sorter: (a, b) => a.residentAccount.localeCompare(b.residentAccount), // Sort by resident account
            sortDirections: ['ascend', 'descend'], // Sort icon
        },
    ];
    useEffect(() => {
        const loadDocuments = async () => {
            setIsLoadingLotDocs(true);
            try {
                await ricStore.getLotDocuments({ "CommunityId": `${ricStore.accountInformation?.companyKey}`, "Office": ``, "filters": [`ResidentAccount:(\"${ricStore.accountInformation?.accountNumber}\")`], "searchText": null });
            } finally {
                setIsLoadingLotDocs(false);
            }
        };
        loadDocuments();
    }, [ricStore]);

    const handleRowClick = async (record: any) => {
        setIsLoadingFile(true);
        setFileData(null);

        try {
            const response = await ricStore.getFile({
                CommunityId: `${ricStore.accountInformation?.companyKey}`,
                Office: ``,
                FilePath: record.fullPath,
            });

            if (!response) {
                message.error("Failed to fetch file");
                return;
            }

            const byteArray: number[] = Array.from(atob(response), char => char.charCodeAt(0));
            const mimeIsImage = getMimeImageType(record.localName);
            const isMobileDevice = /Mobi|Android|iPhone|iPad|iPod/i.test(navigator.userAgent);

            if (mimeIsImage.mimeType.length && (!mimeIsImage.isPdf || (!isMobileDevice && mimeIsImage.isPdf))) {
                const blob = new Blob([new Uint8Array(byteArray)], { type: mimeIsImage.mimeType });
                setFileData(blob);
            } else {
                FileHelper.DownloadFile(byteArray, record.localName);
            }

            setIsModalVisible(true);
        } catch (error) {
            message.error(`Error loading file: ${error}`);
        } finally {
            setIsLoadingFile(false);
        }
    };

    const handleCancel = () => {
        setIsModalVisible(false);
        setFileData(null);
    };

    const renderFile = () => {
        if (!fileData) return null;

        const fileUrl = URL.createObjectURL(fileData);
        return <iframe src={fileUrl} width="100%" height="500px" title="Document Viewer" />;
    };

    return (
        <>
            {(isLoadingLotDocs || isLoadingFile) && <Spinner />}
            <div style={{ padding: "10px" }}>
                <Table
                    columns={columns}
                    dataSource={ricStore.lotDocuments ?? []}
                    onRow={(record) => ({
                        onClick: () => handleRowClick(record),
                    })}
                    onChange={(pagination, filters, sorter) => {
                        // Optionally handle sort/filter changes if needed
                    }}
                />
                <Modal
                    title="Document View"
                    visible={isModalVisible}
                    onCancel={handleCancel}
                    footer={null}
                    width={800}
                    destroyOnClose={true}
                >
                    {isLoadingFile ? (
                        <Spin />
                    ) : (
                        renderFile()
                    )}
                </Modal>
            </div>
        </>
    );
};

export default LotDocuments;
