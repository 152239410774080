import { Col, Row } from 'antd';
import React, { useEffect } from 'react';
import { observer } from "mobx-react-lite";
import {  useParams,useNavigate } from "react-router-dom";
import { useDispatch } from 'react-redux';
import AccountInfoBlock from '../components/AccountInfo/AccountInfoBlock';
import TableAccountInfo from '../components/TableAccountInfo';
import { useStore } from '../../../stores/root-store-context';
import { ModalFileViewer } from "../../ModalFileViewer/ModalFileViewer";
import Spinner from 'Components/Spinner/Spiner';
import Headers from '../../../../../../../../redux/Actions/Headers/Header';

const AccountInfo =  observer(() => {
    const dispatch = useDispatch();
    const navigate = useNavigate();
     const {ricStore,modalViewerStore,applicationStore} = useStore();
    const { id } = useParams<string>();
    console.log(id,"Ide88878787868768768")
    useEffect(()=>{
if(id){
    ricStore.setAccount(id);
}
    },[id])
    useEffect(() => {
        if (ricStore.selectedAccount) {
          ricStore.getRicInfo().catch(console.log);
        } else {
          ricStore.setShowRicInfo(false);
        }
      }, [ricStore.selectedAccount]);
      useEffect(() => {
        dispatch(
          Headers.setHeader({
            title: "RIC-Resident Information Console",
            actions: [
              {
                label: "back",
                type: "button",
                onClick: () =>  navigate("/AAM/ric", { state: { PrpertyApiCall: true } }),
              },
            ],
          })
        );
        return () => {
          dispatch(Headers.resetHeader());
        };
      }, [dispatch]);
  return (
    <div>
        {( ricStore?.isLoadingApp || ricStore.isLoadingRicData ||applicationStore.isLoading) && <Spinner/>}
    <Row>
      <Col span={24}>
        <AccountInfoBlock />
      </Col>
    </Row>
    <Row>
      <Col span={24}>
        <TableAccountInfo />
      </Col>
    </Row>
    <ModalFileViewer
            isOpenModal={modalViewerStore.isOpenModal}
            dismissModal={modalViewerStore.dissmissModal}
            urlModal={modalViewerStore.urlModal}
            isImage={modalViewerStore.isLocalFileForIframe}
            documentName={modalViewerStore.documentName}
          />
  </div>
  );
});

export default AccountInfo;
