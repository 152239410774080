/* eslint-disable */
import { ReactElement } from "react";
import { Descriptions, Typography } from "antd";
import * as React from "react";
import type { DescriptionsProps } from 'antd';
const { Text } = Typography;

export type AccountInformation = {
  accountNumber: string;
  unitAddress: string;
  property: string;
  cm: string;
  am: string;
  office: string;
  admin: string;
  vp: string;
  delinquencyStatus: string;
  liened: string;
  subLedgerType: string;
  communityName: string;
  unitLot: string;
  unitBlock: string;
  escrowStr: string;
  rcDate5: string;
  salutation: string;

  accountInfo: AccountInformation;
  isReadOnlyUser: boolean;
  query: string;
  loading: boolean;
  message: string;
  companyKey: string;
  autocompleteLoading: boolean;
  autocompleteOpen: boolean;
  autocompleteOptions: any[];
  properties: any[];
  activeTab: string;
  propertyName: string;
  propertyNameInTypeahead: string;
  selectedProperty: any | null;
  propertyInputText: string;
  isActiveAdvancedSearch: boolean;
  isAccessToProperty: boolean;
  ownerFilters: any;
  isLoadLoader: boolean;
  isLoadLoaderZEGO: boolean;
  contactInfo: any | null;
  hideButtons: boolean;
  hideNotes: boolean;
  isDisabledAccountSearch: boolean;
  isDisabledNameSearch: boolean;
  isDisabledAddressSearch: boolean;
  isDisabledETrakContactIDSearch: boolean;
  isOpenDialog: boolean;
  isOpenLoad: boolean;
  isOpenSnack: boolean;
  isAuth: boolean;
  isLoadEnd: boolean;
  dialogFirstName: string | null;
  dialogLastName: string | null;
  dialogDateRecived: Date | null;
  dialogExpirationDate: Date | null;
  dialogDOB: Date | null;
  dialogEmail: string | null;
  dialogPhone: string | null;
  rentersAccNumbers: any[];
  activeRenters: any[];
  noActiveRenters: any[];
  mainAccNumb: string;
  futuresAccNumbers: any[];
  activeFutures: any[];
  noActiveFutures: any[];
  isMainAcc: boolean;
  isAddRenter: number;
  textAccountNumber: string;
  isOpenModalDelete: boolean;
  isLoaderLoad: boolean;
  isETrakType: boolean;
};

const styles = {
  accountInfoContainer: {
    display: "flex",
    justifyContent: "center",
    flexFlow: "row wrap",
    background:"white",
    borderRadius:'12px',
    padding:'18px'
  },
  accountInfoCard: {
    flex: "1 1 260px",
    textAlign: "start" as const,
    alignSelf: "stretch",
    padding: "5px",
    margin: "5px",
    borderRadius: "5px",
  },
  accountInfoField: {
    textOverflow: "ellipsis",
    display: "-webkit-box",
    WebkitBoxOrient: "vertical" as const,
    WebkitLineClamp: "1",
    overflow: "hidden",
    marginBottom: "2px",
  },
  accountInfoOverflowField: {
    textOverflow: "ellipsis",
    display: "-webkit-box",
    WebkitBoxOrient: "vertical" as const,
    WebkitLineClamp: "3",
    overflow: "hidden",
    marginBottom: "2px",
  },
  infinityLineField: {
    display: "-webkit-box",
    WebkitBoxOrient: "vertical" as const,
    marginBottom: "2px",
  },
};
export const InlineField = ({
  label,
  value,
}: {
  label: string | ReactElement;
  value: string | ReactElement;
}) => (
  <div style={styles.accountInfoField}>
    <strong>{label} </strong>
    {value}
  </div>
);
export const ThreeLineField = ({
  label,
  value,
}: {
  label: string | ReactElement;
  value: string | ReactElement;
}) => (
  <div style={styles.accountInfoOverflowField}>
    <strong>{label}</strong>
    <span>{value}</span>
  </div>
);
export const InfinityLineField = ({
  label,
  value,
}: {
  label: string | ReactElement;
  value: string | ReactElement;
}) => (
  <div style={styles.infinityLineField}>
    <strong>{label} </strong>
    <span>{value}</span>
  </div>
);
const AccountInfoCard = (props: {
  accountInfo: AccountInformation;
}): ReactElement => {
  const { accountInfo } = props;
  const items = [
    { label: "Account Number", children: accountInfo.accountNumber },
    { label: "Move In Date", children: accountInfo.escrowStr },
    { label: "Unit Address", children: accountInfo.unitAddress },
    { label: "Legal Owner Name", children: accountInfo.salutation },
    { label: "Property", children: accountInfo.property },
    { label: "Community", children: accountInfo.communityName },
    { label: "Block", children: accountInfo.unitBlock },
    { label: "CM", children: accountInfo.cm },
    { label: "AM", children: accountInfo.am },
    { label: "Office", children: accountInfo.office },
    { label: "Lot", children: accountInfo.unitLot },
    { label: "Admin", children: accountInfo.admin },
    { label: "Regional", children: accountInfo.vp },
    {
      label: "Account Type",
      children: <strong style={{ color: "blue" }}>{accountInfo.subLedgerType?.toUpperCase()}</strong>,
    },
    {
      label: "Delinquency Status",
      children: (
        <span style={{ color: "red" }}>
          {accountInfo.delinquencyStatus?.toUpperCase()} {accountInfo.liened?.toUpperCase()}
        </span>
      ),
    },
  ];
  return (
    <div style={styles.accountInfoContainer}>
      {/* <div style={styles.accountInfoCard}>
        
        <InlineField
          label="Account Number:"
          value={accountInfo.accountNumber}
        />
        <InlineField label="Move In Date:" value={accountInfo.escrowStr} />
        <ThreeLineField label="Unit Address:" value={accountInfo.unitAddress} />
        <ThreeLineField
          label="Legal Owner Name:"
          value={accountInfo.salutation}
        />
      </div>
      <div style={styles.accountInfoCard}>
        <InlineField
          label="Account Type:"
          value={
            <strong style={{ color: "blue" }}>
              {accountInfo.subLedgerType?.toUpperCase()}
            </strong>
          }
        />
        <InlineField
          label=""
          value={
            <>
              <strong>
                <Text type="danger">
                  {accountInfo.liened?.toUpperCase()}{" "}
                  {accountInfo.liened?.toUpperCase() &&
                    accountInfo.delinquencyStatus?.toUpperCase()}
                </Text>
              </strong>
              {(accountInfo.liened?.toUpperCase() &&
                accountInfo.subLedgerType?.toUpperCase()) ||
              (accountInfo.delinquencyStatus?.toUpperCase() &&
                accountInfo.subLedgerType?.toUpperCase()) ? (
                <strong> / </strong>
              ) : (
                " "
              )}
              <strong>
                <Text type="danger">
                  {accountInfo.delinquencyStatus?.toUpperCase()}
                </Text>
              </strong>
            </>
          }
        />
      </div>
      <div style={styles.accountInfoCard}>
        <InlineField label="Property:" value={accountInfo.property} />
        <InlineField label="Community:" value={accountInfo.communityName} />
        <InlineField label="Block:" value={accountInfo.unitBlock} />
        <InlineField label="CM:" value={accountInfo.cm} />
        <InlineField label="AM:" value={accountInfo.am} />
      </div>
      <div style={styles.accountInfoCard}>
        <ThreeLineField label="Office:" value={accountInfo.office} />
        <InlineField label="Lot:" value={accountInfo.unitLot} />
        <InlineField label="Admin:" value={accountInfo.admin} />
        <InlineField label="Regional:" value={accountInfo.vp} />
      </div> */}
      <Descriptions title="User Info"  
        items={items} />
    </div>
  );
};

export default AccountInfoCard;
