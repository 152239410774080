/* eslint-disable */
import {  ReactElement, useState } from "react";
import {
  Alert,
  App,
  Button,
  Col,
  DatePicker,
  Divider,
  Dropdown,
  Flex,
  Form,
  Input,
  MenuProps,
  Popconfirm,
  Row,
  Space,
  Spin,
  Typography,
} from "antd";
import { InlineField, ThreeLineField } from "./AccountInfo/AccountInfoCard";
import { CloseOutlined, PlusOutlined } from "@ant-design/icons";
import * as React from "react";
import { useStore } from "../../../stores/root-store-context";
import { observer } from "mobx-react-lite";
import dayjs from "dayjs";
import { clone } from "lodash";

import {
  ContactInformation,
  ContactType,
  PhoneData,
  PhoneInputNumber,
} from "./ContactInfo";

const styles = {
  contactInfoContainer: {
    display: "flex",
    justifyContent: "start",
    flexFlow: "row wrap",
    borderBottom: "1px solid lightgray",
  },
  contactInfoCard: {
    flex: "1 1 300px",
    textAlign: "start" as const,
    alignSelf: "stretch",
    padding: "5px",
    margin: "5px",
  },
  buttonClose: {
    background: "none",
    border: "none",
  },
  phoneContainer: {
    display: "block",
    width: 300,
    marginBottom: 10,
    marginRight: 10,
    height: 40,
    lineHeight: 40,
  },
  phoneError: {
    backgroundColor: "#fdd6de",
  },
  phoneInput: {
    height: 40,
    lineHeight: 40,
  },
  phoneClose: {
    width: "38px",
    background: "red",
  },
  phoneName: {},
};

const AddContactInfo = observer(
  (props: {
    errorMessage?: string; 
    contactInfo: ContactInformation;
    updateAccount: (
      contactData: ContactInformation,
      modifiedString?: string | null
    ) => void;
    deleteAccount: (
      methodId: number,
      type: string,
      deletedValue: string
    ) => void;
    isNonPopconfirm?: boolean;
  }): ReactElement => {
    const { ricStore, applicationStore } = useStore();

    const { updateAccount, deleteAccount,errorMessage } = props;
    const [localContactInfo, setLocalContactInfo] =
      useState<ContactInformation>(
        clone({ ...props.contactInfo, phones: clone(props.contactInfo.phones) })
      );
    const [isUpdating, setIsUpdating] = useState<boolean>(false);
    const [isValidemail, setValidEmail] = useState<boolean>(true);
    const { message } = App.useApp();

    const error = (): void => {
      message.open({
        type: "error",
        content: "Error occurred during saving. Please check the entered data.",
      })();
    };

    const onClick: MenuProps["onClick"] = ({ key }) => {
      const type = ContactType[key as keyof typeof ContactType];
      if (
        type === ContactType.HOME ||
        type === ContactType.MOBILE ||
        type === ContactType.WORK
      ) {
        setLocalContactInfo((prev) => {
          return {
            ...prev,
            phones: [
              ...(prev?.phones || []),
              {
                methodId: 0,
                number: "",
                phoneType:
                  key.charAt(0).toUpperCase() + key.slice(1).toLowerCase(),
                type: type,
              },
            ],
          };
        });
      }
      if (type === ContactType.EMAIL) {
        setLocalContactInfo((prev) => {
          return {
            ...prev,
            email: "",
          };
        });
      }
      if (type === ContactType.NOTE) {
        setLocalContactInfo((prev) => {
          return {
            ...prev,
            note: "",
          };
        });
      }
      if (type === ContactType.DATERECEIVED) {
        setLocalContactInfo((prev) => {
          return {
            ...prev,
            dateReceived: dayjs().format("M/D/YYYY"),
          };
        });
      }
      if (type === ContactType.EXPIRATIONDATE) {
        setLocalContactInfo((prev) => {
          return {
            ...prev,
            expirationDate: dayjs().format("M/D/YYYY"),
          };
        });
      }
      if (type === ContactType.BIRTHDATE) {
        setLocalContactInfo((prev) => {
          return {
            ...prev,
            birthdate: dayjs().format("M/D/YYYY"),
          };
        });
      }
    };

    const getOptionContactInformation = (
      contactInfo: ContactInformation
    ): MenuProps => {
      const items: MenuProps["items"] = [];
      if (!contactInfo.email) {
        items.push({
          key: "EMAIL",
          label: "Email",
        });
      }
      if (!contactInfo.note) {
        items.push({
          key: "NOTE",
          label: "Note",
        });
      }
      if (!contactInfo.dateReceived) {
        items.push({
          key: "DATERECEIVED",
          label: "HOPA Date Received",
        });
      }
      if (!contactInfo.expirationDate) {
        items.push({
          key: "EXPIRATIONDATE",
          label: "HOPA Expiration Date",
        });
      }
      if (!contactInfo.birthdate) {
        items.push({
          key: "BIRTHDATE",
          label: "D.O.B.",
        });
      }
console.log(contactInfo.phones,"contactInfo.phones")
      if (!(contactInfo.phones?.length >= 4)) {
        items.push({
          key: "MOBILE",
          label: "Mobile",
        });
        items.push({
          key: "HOME",
          label: "Home",
        });
        items.push({
          key: "WORK",
          label: "Work",
        });
      }
      return { items, onClick };
    };

    function comparePhones(
      newPhones: PhoneData[],
      oldPhones: PhoneData[],
      contactId: string,
      contactName: string
    ): string {
      const messages: string[] = [];

      newPhones.forEach((newPhone, index) => {
        const oldPhone =
          oldPhones && oldPhones.length > 0 ? oldPhones[index] : undefined;
        if (oldPhone) {
          if (newPhone.number !== oldPhone.number) {
            messages.push(
              `${newPhone.phoneType} modified from '${oldPhone.number}' to '${newPhone.number}' for contact id '${contactId}', ${contactName}`
            );
          }
        } else {
          messages.push(
            `New ${newPhone.phoneType} of '${newPhone.number}' added for contact id '${contactId}', ${contactName}`
          );
        }
      });

      return messages.join("\n");
    }

    function compareObjects(
      newObj: any,
      oldObj: any,
      contactId: any,
      contactName: any
    ): string {
      let changes = [];
      for (const key in newObj) {
        if (
          key !== "phones" &&
          Object.prototype.hasOwnProperty.call(newObj, key)
        ) {
          if (oldObj[key] !== newObj[key]) {
            changes.push(
              `${key} modified from '${oldObj[key]}' to '${newObj[key]}'`
            );
          }
        }
      }

      for (const key in oldObj) {
        if (
          key !== "phones" &&
          Object.prototype.hasOwnProperty.call(oldObj, key) &&
          !Object.prototype.hasOwnProperty.call(newObj, key)
        ) {
          changes.push(`${key} added with value '${oldObj[key]}'`);
        }
      }

      return changes
        .map(
          (change) => `${change} for contact id '${contactId}', ${contactName}`
        )
        .join(", ");
    }
    const pattern = /\d{3}-\d{3}-\d{4}/;
    React.useEffect(()=>{
ricStore.SetPhoneLength(localContactInfo?.phones)
    },[localContactInfo])
    return (
      <Spin spinning={isUpdating}>
         {/* <h3 >Additional Contact</h3> */}
         <Divider />
         {ricStore.isEditable && (
          <Dropdown
            menu={getOptionContactInformation(localContactInfo)}
            trigger={["click"]}
          >
            <a
              onClick={(e) => {
                e.preventDefault();
              }}
            >
              <Space>
                <Button type="primary" shape="circle" icon={<PlusOutlined />} />{" "}
                <span style={{color:'#5c7a93'}}>
                  Click to Add Additional Contact Information
                </span>
              </Space>
            </a>
          </Dropdown>
        )}
         {errorMessage && (
          <Alert
            message={errorMessage}
            type="error"
            // showIcon
            // closable
            // onClose={() => {}}
          />
        )}
        <Row style={{marginTop:"20px"}}>
            {localContactInfo.phones &&
              localContactInfo.phones?.map((el, index, phones) => {
            return  <Col span={12}>
                <PhoneInputNumber
                  key={el.methodId}
                  disabled={!ricStore.isEditable}
                  phoneData={clone(el)}
                  onChange={(phonedata: PhoneData) => {
                    phones[index] = phonedata;
                    console.log(JSON.parse(JSON.stringify(phones)),phonedata,"phonedataphonedataphonedata");
                    setLocalContactInfo((prev) => {
                      return { ...prev, phones: phones };
                    });
           
                  }}
                  onRemove={(phoneData) => {
                    const newPhones = phones.filter(
                      (el) => el.methodId !== phoneData.methodId
                    );
                    const newData: ContactInformation = {
                      ...localContactInfo,
                      phones: newPhones,
                    };
               
                    setLocalContactInfo((prev) => {
                      return { ...prev, phones: newPhones };
                    });
                    updateAccount(newData);
                    deleteAccount(
                      phoneData.methodId,
                      phoneData.phoneType,
                      phoneData.number || ""
                    );
                  }}
                  onBlur={(e) => {
                    const prev =
                      ricStore.contactInformation.find(
                        (el) => el.contactId === localContactInfo.contactId
                      ) || props.contactInfo;

                    const changeMessages = compareObjects(
                      localContactInfo,
                      prev,
                      localContactInfo.contactId,
                      localContactInfo.contactFullName
                    );
                    const changeMessagesPhones = comparePhones(
                      localContactInfo.phones,
                      prev.phones,
                      localContactInfo.contactId,
                      localContactInfo.contactFullName
                    );
                    const modifiedString =
                      `${changeMessages}${changeMessagesPhones}`.length > 0
                        ? `${changeMessages}${changeMessagesPhones}`
                        : null;
                    const pattern = /[0-9_]{3}-[0-9_]{3}-[0-9_]{4}/;
                    // if (pattern.test(e.target.value)) {
                      updateAccount(localContactInfo, modifiedString);
                    // }
                  }}
                  isNonPopconfirm={props.isNonPopconfirm === true}
                />
              </Col>
  })}

            {localContactInfo.note !== null &&
            localContactInfo.note !== undefined ? (
              <Col span={12}>
                 <Form.Item
   
        name="note"
        rules={[{ required: true, message: 'Please input!' }]}
      >
              <Input
                id="note"
                style={styles.phoneContainer}
                styles={{
                  input: styles.phoneInput,
                }}
                disabled={!ricStore.isEditable}
                addonBefore={
                  !props.isNonPopconfirm ? (
                    <Popconfirm
                      title="DELETE CONTACT INFORMATION?"
                      description={
                        <div style={{ width: 250 }}>
                          Please confirm you'd like to delete this contact
                          information.
                        </div>
                      }
                      okText="Delete"
                      cancelText="Cancel"
                      onConfirm={async () => {
                        const newData: ContactInformation = {
                          ...localContactInfo,
                          note: null,
                        };
                        setLocalContactInfo(newData);
                        updateAccount(newData);
                      }}
                    >
                      <Button
                        disabled={!ricStore.isEditable}
                        style={{
                          background: "none",
                          border: "none",
                          color: "#5a7890",
                        }}
                        icon={<CloseOutlined disabled={!ricStore.isEditable} />}
                      />
                    </Popconfirm>
                  ) : (
                    <Button
                      disabled={!ricStore.isEditable}
                      style={{
                        background: "none",
                        border: "none",
                        color: "#5a7890",
                      }}
                      onClick={async () => {
                        const newData: ContactInformation = {
                          ...localContactInfo,
                          note: null,
                        };
                        setLocalContactInfo(newData);
                        updateAccount(newData);
                      }}
                      icon={<CloseOutlined disabled={!ricStore.isEditable} />}
                    />
                  )
                }
                value={localContactInfo.note}
                onChange={(e) => {
                  setLocalContactInfo((prev) => {
                    return { ...prev, note: e.target.value };
                  });
                }}
                onBlur={(e) => {
                  const changeMessages = compareObjects(
                    localContactInfo,
                    props.contactInfo,
                    localContactInfo.contactId,
                    localContactInfo.contactFullName
                  );
                  const changeMessagesPhones = comparePhones(
                    localContactInfo.phones,
                    props.contactInfo.phones,
                    localContactInfo.contactId,
                    localContactInfo.contactFullName
                  );
                  const modifiedString =
                    `${changeMessages}${changeMessagesPhones}`.length > 0
                      ? `${changeMessages}${changeMessagesPhones}`
                      : null;
                  updateAccount(localContactInfo, modifiedString);
                }}
                addonAfter={"Note"}
                placeholder={"Please enter text"}
              /></Form.Item></Col>
            ) : (
              <></>
            )}
         
        
            {localContactInfo.email !== null &&
            localContactInfo.email !== undefined ? (
              <Col span={12}>
                      <Form.Item
   
   name="email"
   rules={[{ required: true, message: 'Please enter email address' }]}
 >
              <Input
                id="email"
                style={styles.phoneContainer}
                styles={{
                  input: styles.phoneInput,
                }}
                type="email"
                pattern="^[^@\s]+@[^@\s]+\.[^@\s]+$"
                status={!isValidemail ? "error" : ""}
                disabled={!ricStore.isEditable}
                addonBefore={
                  !props.isNonPopconfirm ? (
                    <Popconfirm
                      title="DELETE CONTACT INFORMATION?"
                      description={
                        <div style={{ width: 250 }}>
                          Please confirm you'd like to delete this contact
                          information.
                        </div>
                      }
                      okText="Delete"
                      cancelText="Cancel"
                      onConfirm={async () => {
                        const newData: ContactInformation = {
                          ...localContactInfo,
                          email: null,
                          emailMethodId: null,
                        };
                        setLocalContactInfo(newData);
                        updateAccount(newData);
                      }}
                    >
                      <Button
                        disabled={!ricStore.isEditable}
                        style={{
                          background: "none",
                          border: "none",
                          color: "#5a7890",
                        }}
                        icon={<CloseOutlined disabled={!ricStore.isEditable} />}
                      />
                    </Popconfirm>
                  ) : (
                    <Button
                      disabled={!ricStore.isEditable}
                      style={{
                        background: "none",
                        border: "none",
                        color: "#5a7890",
                      }}
                      onClick={async () => {
                        const newData: ContactInformation = {
                          ...localContactInfo,
                          email: null,
                          emailMethodId: null,
                        };
                        setLocalContactInfo(newData);
                        updateAccount(newData);
                      }}
                      icon={<CloseOutlined disabled={!ricStore.isEditable} />}
                    />
                  )
                }
                value={localContactInfo.email}
                onChange={(e) => {
                  setLocalContactInfo((prev) => {
                    return { ...prev, email: e.target.value };
                  });
                  setValidEmail(e.target.checkValidity());
                }}
                onBlur={(e) => {
                  const changeMessages = compareObjects(
                    localContactInfo,
                    props.contactInfo,
                    localContactInfo.contactId,
                    localContactInfo.contactFullName
                  );
                  const changeMessagesPhones = comparePhones(
                    localContactInfo.phones,
                    props.contactInfo.phones,
                    localContactInfo.contactId,
                    localContactInfo.contactFullName
                  );
                  const modifiedString =
                    `${changeMessages}${changeMessagesPhones}`.length > 0
                      ? `${changeMessages}${changeMessagesPhones}`
                      : null;
                  if (e.target.checkValidity()) {
                    updateAccount(localContactInfo, modifiedString);
                  }
                }}
                addonAfter={"Email"}
                placeholder={"Please enter valid email address"}
              /></Form.Item></Col>
            ) : (
              <></>
            )}
           
              {localContactInfo.dateReceived !== null &&
              localContactInfo.dateReceived !== undefined ? (
                <Col span={12}>
                <div
                  style={{
                    display: "inline-flex",
                    minWidth: 300,
                    marginBottom: 10,
                    marginRight: 10,
                    height: 40,
                    lineHeight: 40,
                    background: "#00000005",
                    border: "1px solid #d9d9d9",
                    borderRadius: 6,
                  }}
                >
                  {!props.isNonPopconfirm ? (
                    <Popconfirm
                      title="DELETE CONTACT INFORMATION?"
                      description={
                        <div style={{ width: 250 }}>
                          Please confirm you'd like to delete this contact
                          information.
                        </div>
                      }
                      okText="Delete"
                      cancelText="Cancel"
                      onConfirm={async () => {
                        const newData: ContactInformation = {
                          ...localContactInfo,
                          dateReceived: null,
                        };
                        setLocalContactInfo(newData);
                        updateAccount(newData);
                      }}
                    >
                      <Button
                        disabled={!ricStore.isEditable}
                        style={{
                          background: "none",
                          border: "none",
                          color: "#5a7890",
                          width: 110,
                          flex: "0 0 110",
                        }}
                        icon={<CloseOutlined disabled={!ricStore.isEditable} />}
                      />
                    </Popconfirm>
                  ) : (
                    <Button
                      disabled={!ricStore.isEditable}
                      style={{
                        background: "none",
                        border: "none",
                        color: "#5a7890",
                        width: 110,
                        flex: "0 0 110",
                      }}
                      onClick={async () => {
                        const newData: ContactInformation = {
                          ...localContactInfo,
                          dateReceived: null,
                        };
                        setLocalContactInfo(newData);
                        updateAccount(newData);
                      }}
                      icon={<CloseOutlined disabled={!ricStore.isEditable} />}
                    />
                  )}
                  <DatePicker
                    onChange={async (date) => {
                      const newData: ContactInformation = {
                        ...localContactInfo,
                        dateReceived: date.format("M/D/YYYY"),
                      };
                      setLocalContactInfo(newData);
                      const changeMessages = compareObjects(
                        localContactInfo,
                        props.contactInfo,
                        localContactInfo.contactId,
                        localContactInfo.contactFullName
                      );
                      const changeMessagesPhones = comparePhones(
                        localContactInfo.phones,
                        props.contactInfo.phones,
                        localContactInfo.contactId,
                        localContactInfo.contactFullName
                      );
                      const modifiedString =
                        `${changeMessages}${changeMessagesPhones}`.length > 0
                          ? `${changeMessages}${changeMessagesPhones}`
                          : null;
                      updateAccount(newData, modifiedString);
                    }}
                    defaultValue={dayjs(localContactInfo.dateReceived)}
                    title="HOPA Date Received"
                    style={{ borderRadius: 0, flex: "1 1 220px" }}
                    format={"M/D/YYYY"}
                  />
                  <span
                    color="#000000"
                    style={{
                      flex: "1 1 100%",
                      lineHeight: "40px",
                      paddingLeft: 5,
                      whiteSpace: "break-spaces",
                    }}
                  >
                    HOPA Date Received
                  </span>
                </div></Col>
              ) : (
                <></>
              )}
           
           <Col span={12}>
              {localContactInfo.expirationDate !== null &&
              localContactInfo.expirationDate !== undefined ? (
                <div
                  style={{
                    display: "inline-flex",
                    minWidth: 300,
                    marginBottom: 10,
                    marginRight: 10,
                    height: 40,
                    lineHeight: 40,
                    background: "#00000005",
                    border: "1px solid #d9d9d9",
                    borderRadius: 6,
                  }}
                >
                  {!props.isNonPopconfirm ? (
                    <Popconfirm
                      title="DELETE CONTACT INFORMATION?"
                      description={
                        <div style={{ width: 250 }}>
                          Please confirm you'd like to delete this contact
                          information.
                        </div>
                      }
                      okText="Delete"
                      cancelText="Cancel"
                      onConfirm={async () => {
                        const newData: ContactInformation = {
                          ...localContactInfo,
                          expirationDate: null,
                        };
                        setLocalContactInfo(newData);
                        updateAccount(newData);
                      }}
                    >
                      <Button
                        disabled={!ricStore.isEditable}
                        style={{
                          background: "none",
                          border: "none",
                          color: "#5a7890",
                          width: 110,
                          flex: "0 0 110",
                        }}
                        icon={<CloseOutlined disabled={!ricStore.isEditable} />}
                      />
                    </Popconfirm>
                  ) : (
                    <Button
                      disabled={!ricStore.isEditable}
                      style={{
                        background: "none",
                        border: "none",
                        color: "#5a7890",
                        width: 110,
                        flex: "0 0 110",
                      }}
                      onClick={async () => {
                        const newData: ContactInformation = {
                          ...localContactInfo,
                          expirationDate: null,
                        };
                        setLocalContactInfo(newData);
                        updateAccount(newData);
                      }}
                      icon={<CloseOutlined disabled={!ricStore.isEditable} />}
                    />
                  )}
                  <DatePicker
                    onChange={async (date) => {
                      const newData: ContactInformation = {
                        ...localContactInfo,
                        expirationDate: date.format("M/D/YYYY"),
                      };
                      setLocalContactInfo(newData);
                      const changeMessages = compareObjects(
                        localContactInfo,
                        props.contactInfo,
                        localContactInfo.contactId,
                        localContactInfo.contactFullName
                      );
                      const changeMessagesPhones = comparePhones(
                        localContactInfo.phones,
                        props.contactInfo.phones,
                        localContactInfo.contactId,
                        localContactInfo.contactFullName
                      );
                      const modifiedString =
                        `${changeMessages}${changeMessagesPhones}`.length > 0
                          ? `${changeMessages}${changeMessagesPhones}`
                          : null;
                      updateAccount(newData, modifiedString);
                    }}
                    defaultValue={dayjs(localContactInfo.expirationDate)}
                    title="HOPA Expiration Date"
                    style={{ borderRadius: 0, flex: "1 1 220px" }}
                    format={"M/D/YYYY"}
                  />
                  <span
                    color="#000000"
                    style={{
                      flex: "1 1 100%",
                      lineHeight: "40px",
                      paddingLeft: 5,
                      whiteSpace: "break-spaces",
                    }}
                  >
                    HOPA Expiration Date
                  </span>
                </div>
              ) : (
                <></>
              )}
            </Col>
            <Col span={12}>
              {localContactInfo.birthdate !== null &&
              localContactInfo.birthdate !== undefined ? (
                <div
                  style={{
                    display: "inline-flex",
                    minWidth: 300,
                    marginBottom: 10,
                    marginRight: 10,
                    height: 40,
                    lineHeight: 40,
                    background: "#00000005",
                    border: "1px solid #d9d9d9",
                    borderRadius: 6,
                  }}
                >
                  {!props.isNonPopconfirm ? (
                    <Popconfirm
                      title="DELETE CONTACT INFORMATION?"
                      description={
                        <div style={{ width: 250 }}>
                          Please confirm you'd like to delete this contact
                          information.
                        </div>
                      }
                      okText="Delete"
                      cancelText="Cancel"
                      onConfirm={async () => {
                        const newData: ContactInformation = {
                          ...localContactInfo,
                          birthdate: null,
                        };
                        setLocalContactInfo(newData);
                        updateAccount(newData);
                      }}
                    >
                      <Button
                        disabled={!ricStore.isEditable}
                        style={{
                          background: "none",
                          border: "none",
                          color: "#5a7890",
                          width: 110,
                          flex: "0 0 110",
                        }}
                        icon={<CloseOutlined disabled={!ricStore.isEditable} />}
                      />
                    </Popconfirm>
                  ) : (
                    <Button
                      disabled={!ricStore.isEditable}
                      style={{
                        background: "none",
                        border: "none",
                        color: "#5a7890",
                        width: 110,
                        flex: "0 0 110",
                      }}
                      onClick={async () => {
                        const newData: ContactInformation = {
                          ...localContactInfo,
                          birthdate: null,
                        };
                        setLocalContactInfo(newData);
                        updateAccount(newData);
                      }}
                      icon={<CloseOutlined disabled={!ricStore.isEditable} />}
                    />
                  )}
                  <DatePicker
                    defaultValue={dayjs(localContactInfo.birthdate)}
                    onChange={async (date) => {
                      const newData: ContactInformation = {
                        ...localContactInfo,
                        birthdate: date.format("M/D/YYYY"),
                      };
                      setLocalContactInfo(newData);
                      const changeMessages = compareObjects(
                        localContactInfo,
                        props.contactInfo,
                        localContactInfo.contactId,
                        localContactInfo.contactFullName
                      );
                      const changeMessagesPhones = comparePhones(
                        localContactInfo.phones,
                        props.contactInfo.phones,
                        localContactInfo.contactId,
                        localContactInfo.contactFullName
                      );
                      const modifiedString =
                        `${changeMessages}${changeMessagesPhones}`.length > 0
                          ? `${changeMessages}${changeMessagesPhones}`
                          : null;
                      updateAccount(newData, modifiedString);
                    }}
                    title="D.O.B."
                    style={{ borderRadius: 0, flex: "1 1 220px" }}
                    format={"M/D/YYYY"}
                  />
                  <span
                    color="#000000"
                    style={{
                      flex: "1 1 100%",
                      lineHeight: "40px",
                      paddingLeft: 5,
                      whiteSpace: "break-spaces",
                    }}
                  >
                    D.O.B.
                  </span>
                </div>
              ) : (
                <></>
              )}
            </Col>
     
        </Row>
       
      </Spin>
    );
  }
);
export default AddContactInfo;
