/* eslint-disable */
import { makeAutoObservable } from "mobx";
import ApiStore from "./ApiStore";
import {
  Properties,
  UserEmployee,
  allProperiesOption,
} from "../api/base/ConfigApi";

class ApplicationStore {
  private _api: ApiStore;
  SelectedProperties: Properties = {} as Properties;
  PropertieList:  Properties[] = [];
  userEmployee: UserEmployee;
  isLoading: boolean = false;
  isCollapsed: boolean = false;
  searchValue: string;
  propertyLength:number;
  isAdvancedSearch: boolean = true;
  searchTextProperty: string = "";

  constructor(api: ApiStore) {
    makeAutoObservable(this);
    this._api = api;
   
  }
setPropertyLength(lenth:number){
  this.propertyLength = lenth;
}
  setIsLoading(status: boolean): void {
    this.isLoading = status;
  }
  setIsCollapsed(collapse:boolean){
    this.isCollapsed = collapse
  }
  setSearchValue(status: string): void {
    this.searchValue = status;
  }

  setSelectedPropertiesById(id: number): void {
    if (id) {
      const props = this.PropertieList.find((el) => el.id === id);
      if (props) {
        this.SelectedProperties = props;
        this.isAdvancedSearch = true;
      }
    }
    if (id === 0) {
      this.SelectedProperties = allProperiesOption;
      this.isAdvancedSearch = false;
    }
  }

  setSelectedProperties(properties: Properties): void {
    this.SelectedProperties = properties;
  }

  setSearchTextProperty(search: string): void {
    this.searchTextProperty = search;
  }
  setPropertieList = (Properties: Properties[]) => {
    this.PropertieList = Properties;
  };
  public loadProperties(): void {
    this.isLoading = true;
    this._api.RicApiClient.getPropertyList()
      .then((res) => {
        this.setPropertieList(res.data?.properties)
        //this.PropertieList = res.data?.properties || [];
        this.userEmployee = res.data?.userEmployee;
        this.isLoading = false;
      })
      .catch((error) => {
        this.isLoading = false;
        console.log(error);
      });
  }
}
export default ApplicationStore;
