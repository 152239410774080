import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { Row, Col, Card, Button, Typography, List, Modal } from "antd";
import DynamicTable from "../../../Components/Table/Table"; // Assuming DynamicTable is available
import Spinner from "../../../Components/Spinner/Spiner";
//import { useStore } from "../../../root-store-context";
import "../../../assests/styles/base/SubmissionDetails.scss";
import { Tenants } from "../../../redux/Actions";
import CommonList from '../../../utils/CommonList/CommonList'
import Headers from "../../../redux/Actions/Headers/Header";

// Date formatter function
const formatDate = (date) => {
  if (!date) return null; // return null if no date is provided
  const d = new Date(date);
  const month = d.getMonth() + 1; // Months are 0-indexed, so we add 1
  const day = d.getDate();
  const year = d.getFullYear();

  return `${month < 10 ? `0${month}` : month}/${day < 10 ? `0${day}` : day}/${year}`;
};

const { Title, Text } = Typography;

const SubmissionDetails = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { residentKey } = useParams(); 
  const location = useLocation();
  const { submissionId } = location.state ?? {}; // Get the submissionId from the state
  //const { apiStore } = useStore();
  const [submissionDetails, setSubmissionDetails] = useState(null);
  const [isLoading, setIsLoading] = useState(true);
  const [isContactsModalVisible, setIsContactsModalVisible] = useState(false);
  const [tenantEmergencyContacts, setTenantEmergencyContacts] = useState([]);
  const response = useSelector((state) => state?.Tenants);
  const submissionById = response?.getTenantById;
  


  // Fetch detailed submission data
  useEffect(() => {
    if (submissionId) {
      console.log(submissionId);
      const req = {
        id: submissionId
      };
      dispatch(Tenants.GetTenantSubmissionsByIdRequest(req));
    }
  }, [submissionId]);
  useEffect(() => {
    dispatch(
      Headers.setHeader({
        title: "Registrations Details",
        actions: [
        ],
      })
    );
    return () => dispatch(Headers.resetHeader());
  }, [dispatch]);
  useEffect(() => {
    if (submissionById) {
      console.log(submissionById);
          setSubmissionDetails(submissionById?.data);
          setIsLoading(false);
    }
  }, [submissionById]);

  if (isLoading) return <Spinner />;

  if (!submissionDetails) {
    return <div>No details available for this submission.</div>;
  }

  // Columns for dynamic tables
  const tenantColumns = [
    { title: "Name", dataIndex: "name", key: "name" },
    { title: "Email", dataIndex: "email", key: "email" },
    { title: "Phone", dataIndex: "phone", key: "phone" },
   // { title: "Birth Date", dataIndex: "birthDate", key: "birthDate" },
    {
      title: "Violation",
      dataIndex: "violation",
      key: "violation",
      render: (text) => (text ? "Yes" : "No"),
    },
    {
      title: "Action",
      key: "action",
      render: (_, tenant) => (
        <Button
          type="link"
          onClick={() => handleViewContacts(tenant.key)}
        >
          View Contacts
        </Button>
      ),
    },
  ];


  // Transform data for DynamicTable
  const transformTenants = submissionDetails.tenants?.map((tenant) => ({
    key: tenant.id,
    name: `${tenant.firstName} ${tenant.lastName}`,
    email: tenant.email,
    phone: tenant.phone,
    violation: tenant.violation,
    sendConfirmationMail: tenant.sendCopyToTenant,
    emergencyContacts: tenant.emergencyContacts
    //birthDate: tenant.birthDate ? formatDate(tenant.birthDate) : '',
  }));

  const transformVehicles = submissionDetails.vehicles?.map((vehicle) => ({
    key: vehicle.requestId,
    make: vehicle.make,
    model: vehicle.model,
    year: vehicle.year,
    color: vehicle.color,
    license: vehicle.license,
    state: vehicle.state,
    spot: vehicle.spot,
  }));

  const transformPets = submissionDetails.pets?.map((pet) => ({
    key: pet.name, 
    name: pet.name,
    type: pet.type,
    breed: pet.breed,
    rabiesDueDate: pet.rabiesDueDate ? formatDate(pet.rabiesDueDate) : '',
    license: pet.license,
    age: pet.age,
  }));

  const transformLeases = {
    startDate:  submissionDetails?.leaseInfo.startDay ? formatDate( submissionDetails?.leaseInfo.startDay) : '',
    endDate:  submissionDetails?.leaseInfo.endDay ? formatDate( submissionDetails?.leaseInfo.endDay) : '',
    amount: '$' + (submissionDetails.amount ?  submissionDetails.amount : "0"), // Fixed to use lease.amount, not submissionDetails.amount
    status: submissionDetails?.leaseInfo.newOrRenew, // Fixed to use lease.amount, not submissionDetails.amount
  };

  const transformPayments = {
    key: submissionDetails?.paymentDetails.id,
    amount: '$' + (submissionDetails?.paymentDetails.amount ? submissionDetails?.paymentDetails.amount : "0"),
    paymentMethod: submissionDetails?.paymentDetails.paymentMethod,
    paymentStatus:
      submissionDetails?.paymentStatus === 0
        ? 'Pending'
        : submissionDetails?.paymentStatus === 1
        ? 'Done'
        : submissionDetails?.paymentStatus === 2
        ? 'Fail'
        : 'Renewal',
    cardHolderName: submissionDetails?.paymentDetails.cardHolderName,
    cardNumber: submissionDetails?.paymentDetails.cardNumber,
    transactionId: submissionDetails?.paymentDetails.transactionId,
  };
  const fullMailingAddress = `${submissionDetails?.mailingAddress}` +
  (submissionDetails?.mailingCity && submissionDetails?.mailingCity.trim() !== "" 
    ? `, ${submissionDetails?.mailingCity}, ${submissionDetails?.mailingState}` 
    : "") +
  (submissionDetails?.mailingZip && submissionDetails?.mailingZip.trim() !== "" 
    ? `, ${submissionDetails?.mailingZip}` 
    : "");

    const handleViewContacts = (tenantId) => {
      // Find the tenant's emergency contacts based on their id
      const tenant = submissionDetails.tenants.find(t => t.id === tenantId);
      
      // Combine firstName and lastName to create contactName for each emergency contact
      const emergencyContacts = tenant?.emergencyContacts?.map(contact => ({
        contactName: `${contact.firstName} ${contact.lastName}`, // Combine first and last name
        relationship: contact.relationship,
        phone: contact.phone,
        email:contact.email
      })) || [];
    console.log(emergencyContacts,"emerge");
      setTenantEmergencyContacts(emergencyContacts);
      setIsContactsModalVisible(true);
    };
  
    // Modal columns for emergency contacts
    const emergencyContactColumns = [
      { title: "Contact Name", dataIndex: "contactName", key: "contactName" },
      { title: "Relationship", dataIndex: "relationship", key: "relationship" },
      { title: "Phone", dataIndex: "phone", key: "phone" },
      { title: "Email", dataIndex: "email", key: "email" },
    ];

  return (
    <Row justify="center">
      <Col span={20}>
      {residentKey ? (
        <Button
          style={{
            borderRadius: 19,
            border: "1px solid #fff",
            color: "#fff",
            backgroundColor: "#5A7890",
            height: "2rem",
            width: "7rem",
            marginBottom: "1rem",
          }}
          onClick={() => navigate(`./TenantRegistrations/${residentKey || ""}`)}
        >
          Back To List
        </Button>
      ):
      (
        <Button
          style={{
            borderRadius: 19,
            border: "1px solid #fff",
            color: "#fff",
            backgroundColor: "#5A7890",
            height: "2rem",
            width: "7rem",
            marginBottom: "1rem",
          }}
          onClick={() => navigate(`/AAM/TenantRegistrations`)}
        >
          Back To List
        </Button>
      )
      }
        
        <Card bordered={false} className="submission-card tenant-detail-main-card p-4">
          <div className="tenant-detail-information-wrap">
            <Text strong>Selected Property:</Text> <Text>{submissionDetails?.property}, {submissionDetails?.city}, {submissionDetails?.state} </Text>
            <br />
            <Text strong>Property Address:</Text> <Text>{submissionDetails?.propertyFullAddress}, {submissionDetails?.propertyCity}, {submissionDetails?.propertyState}, {submissionDetails?.propertyZip}</Text>
            <br />
            <Text strong>Mailing Address:</Text> <Text>{fullMailingAddress}</Text>
            <br />
            <Text strong>Submitter Name:</Text> <Text>{submissionDetails?.submitterFirstName} {submissionDetails?.submitterLastName}</Text>
            <br />
            <Text strong>Submitter Email:</Text> <Text>{submissionDetails?.submitterEmail}</Text>
            <br />
            <Text strong>Submitter Telephone:</Text> <Text>{submissionDetails?.submitterTelephone}</Text>
            <br />
            <Text strong>Status:</Text> <Text>{submissionDetails?.status == 0?"Pending":(submissionDetails?.status == 1?"Approved":"Rejected")}</Text>
            <br />
            <Text strong>Submission Date:</Text> <Text>{formatDate(submissionDetails?.submissionDate)}</Text>
            <br />
            <Text strong>Payment Status:</Text> <Text>{transformPayments?.paymentStatus}</Text>
          

          {submissionDetails?.paymentStatus === 1 &&(<>
            <br />
            <Text strong>Payment Method:</Text> <Text>{transformPayments.paymentMethod}</Text>
            <br />
            <Text strong>Card Holder Name:</Text> <Text>{transformPayments.cardHolderName}</Text>
            {/* <br />
            <Text strong>Card Number:</Text> <Text>{transformPayments.cardNumber}</Text> */}
            <br />
            <Text strong>Transaction Id:</Text> <Text>{transformPayments.transactionId}</Text>
          
          </>)}
          </div>
          {/* Lease Information Section */}
          <Title level={3} className="tenant-details-heading">Lease Information</Title>
          <DynamicTable className="tenant-details-table" columns={CommonList?.leaseColumns} data={[transformLeases] ?? []} />

          {/* Payment Information Section
          <Title level={3}>Payment Information</Title>
          <DynamicTable columns={paymentColumns} data={[transformPayments] ?? []} /> */}

          {/* Tenants Section */}
          <Title level={3} className="tenant-details-heading">Tenants Information</Title>
          <DynamicTable className="tenant-details-table" columns={tenantColumns} data={transformTenants ?? []} />

          {/* Modal for Emergency Contacts */}
          <Modal
            title="Emergency Contacts"
            visible={isContactsModalVisible}
            onCancel={() => setIsContactsModalVisible(false)}
            footer={[
              <Button key="close" onClick={() => setIsContactsModalVisible(false)}>
                Close
              </Button>,
            ]}
          >
            <DynamicTable className="tenant-details-table" columns={emergencyContactColumns} data={tenantEmergencyContacts ?? []} pagination={false} />

          </Modal>

          {/* Vehicles Section */}
          <Title level={3} className="tenant-details-heading">Vehicles Information</Title>
          <DynamicTable className="tenant-details-table" columns={CommonList?.vehicleColumns} data={transformVehicles ?? []} />

          {/* Pets Section */}
          <Title level={3} className="tenant-details-heading">Pets Information</Title>
          <DynamicTable className="tenant-details-table" columns={CommonList?.petColumns} data={transformPets ?? []} />

          {/* Supporting Documents Section */}
          <Title level={3} className="tenant-details-heading">Supporting Documents</Title>
          <List
            dataSource={submissionDetails?.documents ?? []}
            renderItem={(doc) => (
              <List.Item>
                <a href={doc.url} download>
                  {doc.originalFileName}
                </a>
              </List.Item>
            )}
          />

          {/* Approval Information Section (if applicable) */}
          {submissionDetails.status !== 0 && (
            <>
              <Title level={3}>Approval Information</Title>
              <div>
                <Text strong>Approval Status:</Text> <Text>{submissionDetails.status === 0 ? "Pending" : submissionDetails.status === 1 ? "Approved" : "Rejected"}</Text>
                <br />
                <Text strong>Approval Remark:</Text> <Text>{submissionDetails.approvalRemark}</Text>
                <br />
                <Text strong>Approved By:</Text> <Text>{submissionDetails.approvalBy}</Text>
                <br />
                <Text strong>Approval Date:</Text> <Text>{formatDate(submissionDetails.approvalDate)}</Text>
              </div>
            </>
          )}
        </Card>
      </Col>
    </Row>
  );
};

export default SubmissionDetails;
