import Constant from "../../Constants/Constants";
class Headers {
static setHeader = (payload) => ({
    type: Constant.SET_HEADER,
    payload,
  });
  
  static resetHeader = () => ({
    type: Constant.RESET_HEADER,
  });
  
}

export default Headers;