/* eslint-disable */
import React, { useState, useEffect } from "react";
import { App, ConfigProvider } from "antd"; 
import RIC from "./webparts/ric/components/Ric"; 
//import { ConfigApi } from "./webparts/ric/api/base/ConfigApi";





const AppComponent = () => {

  return (
    <ConfigProvider
      theme={{
        token: {},
        components: {
          Collapse: {
            colorBorder: "none",
            headerBg: "#ffffff",
            paddingSM: 6,
            padding: 0,
          },
          Button: {
            borderRadius: 4,
            colorPrimary: "#5a7890",
            colorPrimaryHover: "rgba(47, 75, 98, 1)",
            colorPrimaryActive: "rgba(47, 75, 98, 1)",
            colorPrimaryTextActive: "#ffffff",
          },
          Typography: {
            fontSize: 16,
          },
        },
      }}
    >
     
        <App>
          <RIC />
        </App>
    
    </ConfigProvider>
  );
};

export default AppComponent;
