// src/components/Header.js
import React from "react";
import { useDispatch } from "react-redux";
import { Col, Row, Space, Tag } from "antd";
import { useMsal } from "@azure/msal-react";
import { logout } from "../../redux/Actions/Auth/Auth";
import Component from "Components";
import {
  MenuFoldOutlined,
  MenuUnfoldOutlined,
  SettingOutlined,
  LogoutOutlined,
  UserOutlined,
  MailOutlined,
} from "@ant-design/icons";
import { Button } from "antd";
import UserData from "utils/UserData";
import { useStore } from "../../Views/App-Views/RIC/webparts/ric/stores/root-store-context";

const Header = () => {
  const { DropdownComponent } = Component;
  const {applicationStore} = useStore();
  //const { collapsed } = useSelector((state) => state.auth);

  const dispatch = useDispatch();
  const { instance } = useMsal();
  const user = UserData();
  const Logout = async () => {
    await instance.logoutRedirect({
      postLogoutRedirectUri: "/",
      onRedirectNavigate: () => false,
    });
    localStorage.clear();
    dispatch(logout(instance));
  };
  const items = [
    {
      key: "1",
      label: user?.getUserInformation()?.displayName,
      icon: <MailOutlined />,
      disabled: true,
    },
    {
      type: "divider",
    },
    {
      key: "2",
      label: "Profile",
      icon: <UserOutlined />,
      extra: "⌘P",
    },

    {
      key: "4",
      label: "Settings",
      icon: <SettingOutlined />,
      extra: "⌘S",
    },
    {
      key: "3",
      label: (
        <Button type="none" style={{ padding: "0px" }} onClick={Logout}>
          Logout
        </Button>
      ),
      extra: "⌘B",
      icon: <LogoutOutlined />,
    },
  ];
  return (
    <Space
      style={{
        justifyContent: "space-between",
        paddingRight: "29px",
        background: "#fff",
      }}
    >
      <Button
        type="text"
        icon={applicationStore?.isCollapsed ? <MenuUnfoldOutlined /> : <MenuFoldOutlined />}
        onClick={() => applicationStore?.setIsCollapsed(!applicationStore?.isCollapsed )}
        style={{
          fontSize: "16px",
          width: 64,
          height: 64,
        }}
      />
      <Row gutter={[20, 16]} align={"middle"}>
        <Col>
          <Tag>
            <b>Welcome</b>, {user?.getUserName()}
          </Tag>
        </Col>
        <Col>
          <DropdownComponent items={items} title="Profile" />
        </Col>
      </Row>
    </Space>
  );
};

export default Header;
