/* eslint-disable */
import { ChangeEvent, ReactElement, useState } from "react";
import {
  App,
  Button,
  Col,
  DatePicker,
  Descriptions,
  Dropdown,
  Input,
  MenuProps,
  Popconfirm,
  Row,
  Space,
  Spin,
  Table,
} from "antd";
import { ColumnsType } from 'antd/es/table';
import { InlineField, ThreeLineField } from "./AccountInfo/AccountInfoCard";
import { CloseOutlined, EditOutlined, PlusOutlined } from "@ant-design/icons";
import * as React from "react";
import { useStore } from "../../../stores/root-store-context";
import { observer } from "mobx-react-lite";
import dayjs from "dayjs";
import AddContactInfo from "./AddContactInfo";
import EditNameAddressForm from "./AccountInfo/EditNameAddressForm";
import { clone } from "lodash";
import DynamicTable from "Components/Table/Table";
import { render } from "react-dom";


const styles = {
  contactInfoContainer: {
    display: "flex",
    justifyContent: "start",
    flexFlow: "row wrap",
    borderBottom: "1px solid lightgray",
  },
  contactInfoCard: {
    flex: "1 1 300px",
    textAlign: "start" as const,
    alignSelf: "stretch",
    padding: "5px",
    margin: "5px",
  },
  buttonClose: {
    background: "none",
    border: "none",
  },
  phoneContainer: {
    display: "block",
    width: 300,
    marginBottom: 10,
    marginRight: 10,
    height: 40,
    lineHeight: 40,
  },
  phoneError: {
    backgroundColor: "#fdd6de",
  },
  phoneInput: {
    height: 40,
    lineHeight: 40,
  },
  phoneClose: {
    width: "38px",
    background: "red",
  },
  phoneName: {},
};
export type PhoneData = {
  methodId: number;
  phoneType: string;
  type: number;
  number: string | null;
};
export type ContactInformation = {
  accountNumber: string;
  activeFutures: null | any;
  activeRenters: null | any;
  address: string;
  address2: string;
  birthdate: null | any;
  businessPhone: null | any;
  businessPhoneMethodId: null | any;
  contactAddress?: {
    referenceID: string;
    mailingName: string;
    mailingName2: string;
    mailingSalutation: string;
    mailingAddress1: string;
    mailingAddress2: string;
    mailingCity: string;
    mailingRegion: string;
    mailingCountry: string;
    mailingPostalCode: string;
    unitKey: string;
  } | null;
  contactFullName: string;
  contactId: string;
  dateReceived: null | any;
  email: string | null;
  emailMethodId: number | null;
  employeeKey: null | any;
  expirationDate: null | any;
  firstName: string;
  futures: null | any;
  homePhone: null | any;
  homePhoneMethodId: null | any;
  id: string;
  isETrakType: boolean;
  isMain: boolean;
  lastName: string;
  mainAcc: null | any;
  mobilePhone: null | any;
  mobilePhoneMethodId: null | any;
  modifiedString: null | any;
  noActiveFutures: null | any;
  noActiveRenters: null | any;
  note: null | any;
  occupancy: boolean;
  owner: boolean;
  phones: PhoneData[];
  primaryOwner: string;
  rc_Date5: string;
  renters: null | any;
  salutation: string;
  type: string;
  user: null | any;
  writeAccess: boolean;
};
export const PhoneInputNumber = observer(
  ({
    phoneData,
    onChange,
    onBlur,
    onRemove,
    disabled,
    isNonPopconfirm,
  }: {
    phoneData: PhoneData;
    onChange?: (phoneData: PhoneData) => void;
    onBlur?: (event: React.FocusEvent<HTMLInputElement, Element>) => void;
    onRemove?: (phoneData: PhoneData) => void;
    disabled: boolean;
    isNonPopconfirm?: boolean;
  }) => {
    const [phone, setPhone] = useState<string>(
      phoneData.number
        ? phoneData.number.replace(/(\d{3})(\d{3})(\d{4})/, "$1-$2-$3")
        : ""
    );
    const pattern = /\d{3}-\d{3}-\d{4}/;
    const mask = "___-___-____";

    const onChangeInternal = async (event: ChangeEvent<HTMLInputElement>) => {
      if (!disabled && onChange !== undefined) {
        const inputValue = event.target.value;
        const digitsOnly = inputValue.replace(/\D/g, "");
        let numIndex = 0;
        const newMask = mask.replace(
          /_/g,
          (_) => digitsOnly[numIndex++] || "_"
        );
        await setPhone(newMask);
        let lastDigitIndex = newMask.search(/\d(?!.*\d)/);
        lastDigitIndex = lastDigitIndex === -1 ? 0 : lastDigitIndex + 1;
        (event.nativeEvent.target as HTMLInputElement).setSelectionRange(
          lastDigitIndex,
          lastDigitIndex
        );
        // if (pattern.test(newMask)) {
          phoneData.number = inputValue.replace(/\D/g, "");
          onChange(phoneData);
        // }
      }
    };

    return (
      
      <Input
        id="phone"
        style={styles.phoneContainer}
        disabled={disabled}
        // styles={{
        //   input:
        //     !phone || pattern.test(phone)
        //       ? styles.phoneInput
        //       : { ...styles.phoneInput, ...styles.phoneError },
        // }}
        addonBefore={
          !isNonPopconfirm ? (
            <Popconfirm
              title="DELETE CONTACT INFORMATION?"
              description={
                <div style={{ width: 250 }}>
                  Please confirm you'd like to delete this contact information.
                </div>
              }
              okText="Delete"
              cancelText="Cancel"
              onConfirm={() => {
                onRemove && onRemove(phoneData);
              }}
            >
              <Button
                disabled={disabled}
                style={{ background: "none", border: "none", color: "#5a7890" }}
                icon={<CloseOutlined disabled={disabled} />}
              />
            </Popconfirm>
          ) : (
            <Button
              disabled={disabled}
              style={{ background: "none", border: "none", color: "#5a7890" }}
              onClick={() => onRemove && onRemove(phoneData)}
              icon={<CloseOutlined disabled={disabled} />}
            />
          )
        }
      //  pattern="[0-9_]{3}-[0-9_]{3}-[0-9_]{4}"
        value={phone}
        onChange={onChangeInternal}
        onBlur={onBlur}
        onClick={async (
          event: React.MouseEvent<HTMLInputElement, MouseEvent>
        ) => {
          if (!phone || phone === mask) {
            await setPhone("___-___-____");
            (event.target as HTMLInputElement).value = mask;
            (event.target as HTMLInputElement).setSelectionRange(0, 0);
          }
        }}
        addonAfter={phoneData.phoneType}
        placeholder={phoneData.phoneType}
      />
    );
  }
);

export enum ContactType {
  EMAIL = 1,
  WORK = 2,
  HOME = 3,
  MOBILE = 4,
  NOTE = 5,
  PLUSPHONE = 6,
  BIRTHDATE = 11,
  EXPIRATIONDATE = 12,
  DATERECEIVED = 13,
}

export enum TypeForUpdateNote {
  MODIFIED = 1,
  DELETED = 2,
  CREATED = 3,
}
interface ContactInfos {
  contactType: string;
  contactId: string;
  primaryOwner: string;
  contactFullName: string;
  email: string;
  birthdate: string;
  expirationDate: string;
  dateReceived: string;
  note: string;
  address: string;
  address2: string;
  phones: { phoneType: string, number: string }[];
  actions?: string;
}
const ContactInfo = observer(
  (props: {
    contactInfo: ContactInformation[];
    isDelete?: boolean;
  }): ReactElement => {
    const { contactInfo } = props;
    const { ricStore, applicationStore } = useStore();
    const [localContactInfo, setLocalContactInfo] =
      useState<ContactInformation>();
    const [isUpdating, setIsUpdating] = useState<boolean>(false);
    const [isOpenModal, setIsOpenModal] = useState<boolean>(false);
    const { message } = App.useApp();

    const error = (): void => {
      message.open({
        type: "error",
        content: "Error occurred during saving. Please check the entered data.",
      })();
    };

    const deleteAccount = (
      methodId: number,
      type: string,
      deletedValue: string
    ): void => {
      setIsUpdating(true);
      const str = `${type} ${deletedValue} deleted for contact id ${
        localContactInfo.contactId
      }, ${localContactInfo.contactFullName
        ?.replace("&", "")
        .replace("*", "")}`;
      ricStore
        .deleteAccountContactInfo(
          methodId.toString(),
          str,
          localContactInfo.accountNumber,
          applicationStore.userEmployee.employeeID
        )
        .then(() => {
          ricStore.getRicInfo().catch(console.log);
          setIsUpdating(false);
        })
        .catch((err) => {
          console.log(err);
          setIsUpdating(false);
          error();
        });
    };

    const updateAccount = (
      contactData: ContactInformation,
      modifiedString?: string
    ): void => {
      setIsUpdating(true);
      setLocalContactInfo({ ...contactData, modifiedString: modifiedString ?? null });
      // ricStore
      //   .updateAccount(
      //     { ...contactData, modifiedString: modifiedString },
      //     applicationStore.userEmployee.employeeKey,
      //     applicationStore.userEmployee.employeeID
      //   )
      //   .then(() => {
      //     setIsUpdating(false);
      //   })
      //   .catch((err) => {
      //     console.log(err);
      //     setIsUpdating(false);
      //     error();
      //   });
    };
    console.log(contactInfo,"contactInfocontactInfo")
    // const columns = [
    //   {
    //     title: "Contact Type",
    //     dataIndex: "contactType",
    //     key: "contactType",
    //     render: (text,record) => (record?.type === "Owner" ? "All" : record?.type),
    //   },
    //   {
    //     title: "Contact ID",
    //     dataIndex: "contactId",
    //     key: "contactId",
    //   },
    //   {
    //     title: "Owner Status",
    //     dataIndex: "primaryOwner",
    //     key: "primaryOwner",
    //     render: (text) => (text === "Yes" ? "Owner" : "Non-Owner"),
    //   },
    //   {
    //     title: "Contact Full Name",
    //     dataIndex: "contactFullName",
    //     key: "contactFullName",
    //   },
    //   {
    //     title: "Email",
    //     dataIndex: "email",
    //     key: "email",
    //   },
    //   {
    //     title: "Email",
    //     dataIndex: "email",
    //     key: "email",
    //   },
    //   {
    //     title: "D.O.B",
    //     dataIndex: "birthdate",
    //     key: "birthdate",
    //     render:(value,record)=>dayjs(record.birthdate)
    //   },
    //   {
    //     title: "HOPA Expiration Date",
    //     dataIndex: "expirationDate",
    //     key: "expirationDate",
    //     render:(value,record)=>dayjs(record.expirationDate)
    //   },
    //   {
    //     title: "HOPA Date Received",
    //     dataIndex: "dateReceived",
    //     key: "dateReceived",
    //     render:(value,recored)=>dayjs(recored.dateReceived)
    //   },
    //   {
    //     title: "Note",
    //     dataIndex: "note",
    //     key: "note",
    //   },
    //   {
    //     title: "Address",
    //     dataIndex: "address",
    //     key: "address",
    //   },
    //   {
    //     title: "Phones",
    //     key: "phones",
    //     children: [
    //       {
    //         title: "Home",
    //         dataIndex: "phones",
    //         key: "home",
    //         width: 300,
    //         render: (phones) =>
    //           phones?.find((phone) => phone.phoneType === "Home")?.number || "N/A",
    //       },
    //       {
    //         title: "Mobile",
    //         dataIndex: "phones",
    //         key: "mobile",
    //         width: 300,
    //         render: (phones) =>
    //           phones?.find((phone) => phone.phoneType === "Mobile")?.number || "N/A",
    //       },
    //       {
    //         title: "Work",
    //         dataIndex: "phones",
    //         key: "work",
    //         width: 300,
    //         render: (phones) =>
    //           phones
    //             ?.filter((phone) => phone.phoneType === "Work")
    //             .map((phone) => phone.number)
    //             .join(", ") || "N/A",
    //       },
    //     ],
    //   },
    //   {
    //     title: "Address 2",
    //     dataIndex: "address2",
    //     key: "address2"
        
    //   },
    //   {
    //     title: "Actions",
    //     dataIndex: "actions",
    //     key: "actions",
    //     render: (text) => (
    //       <div style={{ display: "flex", justifyContent: "space-between" }}>
    //         <EditOutlined
    //           shape="circle"
    //           onClick={() => {
    //             setIsOpenModal(true);
    //           }}
    //         />
            
    //       </div>
    //     ),
        
    //   },
    // ];
    const columns:ColumnsType<ContactInformation> = [
      {
        title: "Contact Type",
        dataIndex: "contactType",
        key: "contactType",
        fixed: 'left',
        render: (text,record) => (record?.type === "Owner" ? "All" : record?.type),

      },
      {
        title: "Contact ID",
        dataIndex: "contactId",
        key: "contactId",
        fixed: 'left', 
      },
      {
        title: "Owner Status",
        dataIndex: "primaryOwner",
        key: "primaryOwner",
        fixed: 'left', 
        render: (text) => (text === "Yes" ? "Owner" : "Non-Owner"),
      },
      {
        title: "Contact Full Name",
        dataIndex: "contactFullName",
        key: "contactFullName",
        fixed: 'left', 
      },
      {
        title: "Email",
        dataIndex: "email",
        key: "email",
        render:(value,record)=>value ?? "-"
      },
      {
        title: "D.O.B",
        dataIndex: "birthdate",
        key:"birthdate",
        render:(value,record)=> record.birthdate ? dayjs(record.birthdate)?.format('DD-MM-YYYY') : "-"
      },
      {
        title: "HOPA Expiration Date",
        dataIndex: "expirationDate",
        key: "expirationDate",
        render:(value,record)=>record.expirationDate ? dayjs(record.expirationDate)?.format('DD-MM-YYYY') : "-"
      },
      {
        title: "HOPA Date Received",
        dataIndex: "dateReceived",
        key: "dateReceived",
        render:(value,recored)=> recored?.dateReceived ? dayjs(value)?.format('DD-MM-YYYY') : "-"
      },
      {
        title: "Note",
        dataIndex: "note",
        key:"note",
      },
      {
        title: "Address",
        key: "address",
        render: (text, record) => {
          return (
            <div>
              {record.address && <div><strong>Address 1:</strong> {record.address}</div>}
              {record.address2 && <div><strong>Address 2:</strong> {record.address2}</div>}
            </div>
          );
        },
      },
      {
        title: "Phones",
        key: "phones",
        render: (text, record) => {
          return record?.phones?.filter((phone) => phone.number)?.map((phone, index) => (
              <div key={index}>
                <strong>{phone?.phoneType}:</strong> {phone?.number}
              </div>
            ));
        },
      },
      {
        title: "Actions",
        dataIndex: "actions",
        key: "operations",
        fixed: 'right', 
        render: (text,record) => (
          <div style={{ display: "flex", justifyContent: "space-between" }}>
            <EditOutlined
              shape="circle"
              onClick={() => {
               handleEdit(record)
              }}
            />
            
          </div>
        ),
        
      },
    ];
    const handleEdit = (data)=>{
      console.log(data,'data 4334334323423434343434')
      setLocalContactInfo(data)
      setIsOpenModal(true);
    }
    return (
      <Spin spinning={isUpdating}>
        {/* <div > */}
          {/* <div style={{ ...styles.contactInfoCard, flex: "0 1 300px" }}>
            <InlineField
              label="Contact Type:"
              value={
                localContactInfo.type === "Owner" ? "All" : contactInfo.type
              }
            />
            <InlineField
              label="Contact ID:"
              value={localContactInfo.contactId}
            />
            <InlineField
              label="Owner Status:"
              value={
                localContactInfo.primaryOwner === "Yes" ? "Owner" : "Non-Owner"
              }
            />
          </div>
          <div style={styles.contactInfoCard}>
            <ThreeLineField label="" value={localContactInfo.contactFullName} />
            <ThreeLineField label="" value={localContactInfo.address} />
            <Row>
              <Col span={6}>
                <ThreeLineField label="" value={localContactInfo.address2} />
              </Col>
              <Col span={4}>
                <a
                  style={{ textDecoration: "underline" }}
                  onClick={() => {
                    setIsOpenModal(true);
                  }}
                >
                  Edit
                </a>
              </Col>
            </Row>

            <AddContactInfo
              contactInfo={clone(localContactInfo)}
              updateAccount={updateAccount}
              deleteAccount={deleteAccount}
            />
          </div> */}
          {console.log(contactInfo[0],"contactInfocontactInfocontactInfocontactInfo")}
        <div className="ric-table">
        <Table  bordered  columns={columns} dataSource={contactInfo}   size="small"
    scroll={{
      x: 'max-content',
    }} />
        </div>
          {/* {contactInfo.primaryOwner === "No" ? (
            <Popconfirm
              title="PERMANENTLY REMOVE CONTACT"
              description={
                <div style={{ width: 250 }}>
                  Are you sure you want to permanently remove this contact from
                  account? This is not reversible and the contact will lose
                  access to all AAM systems.
                </div>
              }
              okText="Delete"
              cancelText="Cancel"
              onConfirm={async () => {
                setIsUpdating(true);
                ricStore
                  .deleteAccountContactItem(
                    localContactInfo,
                    applicationStore.userEmployee.employeeKey,
                    (status: boolean) => {
                      setIsUpdating(status);
                    }
                  )
                  .catch((error) => {
                    setIsUpdating(false);
                    console.log(error);
                  });
              }}
            >
              <Button
                style={{ marginTop: 10 }}
                disabled={!ricStore.isEditable}
                danger
                type="primary"
              >
                Delete
              </Button>
            </Popconfirm>
          ) : null}
        </div>
        <EditNameAddressForm
          isOpenModal={isOpenModal}
          dismissModal={() => {
            setIsOpenModal(false);
          }}
          contact={contactInfo}
        /> */}
         {/* <AddContactInfo
              contactInfo={clone(localContactInfo)}
              updateAccount={updateAccount}
              deleteAccount={deleteAccount}
            /> */}
            {isOpenModal &&  <EditNameAddressForm
          isOpenModal={isOpenModal}
          dismissModal={() => {
            setIsOpenModal(false);
          }}
          contact={localContactInfo}
           contactInfo={clone(localContactInfo)}
          updateAccount={updateAccount}
          deleteAccount={deleteAccount}
        /> }
  
      </Spin>
    );
  }
);
export default ContactInfo;
