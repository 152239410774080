/* eslint-disable */
import { AutoComplete, Select } from "antd";
import { observer } from "mobx-react-lite";

import React, { useEffect, useState } from "react";
import { useStore } from "../../../../stores/root-store-context";
import AdvancedSearch from "../AdvancedSearch";
import Typography from "antd/es/typography/Typography";
import { useLocation, useNavigate } from "react-router-dom";

const stylesRic = {
  container: {
    display: "flex",
    flexFlow: "row wrap",
    alignItems: "center",
  },
  formField: {
    height: "38px",
    maxHeight: "38px",
    fontSize: "1rem",
    margin: "4px",
  },
};
interface MyComponentProps {
  onChange?: () => void; 
  children?: React.ReactNode;
}
const SelectProperty : React.FC<MyComponentProps> =  observer(({onChange}) => {
  const location = useLocation();
  const navigate = useNavigate();
  const { state } = location;

  const PrpertyApiCall = state?.PrpertyApiCall ?? false;
  const { applicationStore, ricStore } = useStore();
  const [searchText, setSearchText] = useState<string>("");
  useEffect(() => {
    if(!applicationStore?.SelectedProperties?.name){
      applicationStore.loadProperties();

    }
  
  }, [PrpertyApiCall]); 

  return (
    <div >
      <AutoComplete
        style={{
          ...{ minWidth: 350, maxWidth: 400, flex: "1 1 400px" },
          ...stylesRic.formField,
        }}
        aria-label="Please"
      
        searchValue={applicationStore.searchTextProperty}
        value={applicationStore.searchTextProperty}
        options={[
          {
            key: "All",
            value: "All",
            id: 0,
            label: "All",
          },
           ...applicationStore?.PropertieList?.map((item, index) => {
            return {
              key: item?.id,
              value: item?.titleInDropdown,
              id: item?.id,
              label: item?.titleInDropdown,
            };
          }),
        ]}
        placeholder={<b>Please enter property or All...</b>}
        filterOption={(inputValue, option) =>
          option!?.label.toUpperCase().indexOf(inputValue.toUpperCase()) !== -1
        }
        onChange={(value) => {
          applicationStore.setSearchTextProperty(value);
     
        }}
        onSelect={(el, option) => {
          applicationStore.setSelectedPropertiesById(option.id);
          // applicationStore.setSearchTextProperty(
          //   applicationStore.SelectedProperties.titleInDropdown
          // );
          onChange();
          setSearchText(applicationStore.SelectedProperties.titleInDropdown);
          ricStore.setShowRicInfo(false);
          // ricStore.resetRicInfo();
         ricStore.setAccount(undefined);
        }}
        onFocus={() => {
          applicationStore.setSearchTextProperty("");
        }}
        onBlur={() => {
          applicationStore.setSearchTextProperty(
            applicationStore.SelectedProperties.titleInDropdown
          );
        }}
      />
    </div>
  );
});

export default SelectProperty;
