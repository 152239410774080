import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Button, Select, Modal, Input, Table, Card } from "antd";
import { useNavigate, useParams } from "react-router-dom";
import { CheckOutlined, CloseOutlined } from '@ant-design/icons'; 
import Spinner from "../../../Components/Spinner/Spiner";
import "../../../assests/styles/base/SubmissionDetails.scss";
import Swal from "sweetalert2";
import UserData from "utils/UserData";
import { Tenants } from "../../../redux/Actions";
import Headers from "../../../redux/Actions/Headers/Header";

const formatLocalDate = (date) => {
  if (!date) return null; // Return null if no date is provided
  
  const d = new Date(date);

  const localDate = d.toLocaleDateString("en-US", {
    year: "numeric", month: "2-digit", day: "2-digit"
  });
  const localTime = d.toLocaleTimeString("en-US", {
    hour: "2-digit", minute: "2-digit", second: "2-digit"
  });

  return `${localDate} ${localTime}`;
};

const Submissions = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { residentKey } = useParams(); 
  const user = UserData();
  const [isLoadingSubmissions, setIsLoadingSubmissions] = useState(true);
  const [submissions, setSubmissions] = useState([]);
  const [filteredSubmissions, setFilteredSubmissions] = useState([]);
  const response = useSelector((state) => state?.Tenants);
  const allSubmissions = response?.getAllTenants;
  const updateTenantRes = response?.updateTenantById;
  // Modal states
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [remark, setRemark] = useState("");
  const [currentSubmissionId, setCurrentSubmissionId] = useState(null);
  const [currentStatus, setCurrentStatus] = useState(null);

  // Handle the filter functionality
  const handleFilterSubmissions = (value) => {
    if (value === "All") {
      setFilteredSubmissions(submissions); // Show all submissions
    } else {
      const filteredData = submissions.filter((item) => item.status === value);
      setFilteredSubmissions(filteredData); // Show filtered data
    }
  };

  // Fetch the list of submissions
  useEffect(() => {
    setIsLoadingSubmissions(true);
    dispatch(Tenants.GetAllTenantSubmissionsRequest());
  }, []);

  useEffect(() => {
    if (allSubmissions) {
      const transformedData = allSubmissions?.data?.map((item) => ({
        id: item.id,
        property: item.property ?? "",
        submissionDate: item.submissionDate ? formatLocalDate(item.submissionDate) : "",
        submissionType: item.userId != null ? "Home Owner" : "Non Home Owner",
        status: item.status === 0 ? "Pending" : item.status === 1 ? "Approved" : "Rejected",
        payment: item.paymentStatus === 0 ? "Pending" : item.paymentStatus === 1 ? "Done" : item.paymentStatus === 2 ? "Fail" : "Renewal",
        details: item,
        amount: item.amount
      }));
      // Sort submissions by ID in descending order
      const sortedData = transformedData?.sort((a, b) => b.id - a.id);
      setSubmissions(sortedData);
      setFilteredSubmissions(sortedData); // Initialize filtered submissions with sorted data
      setIsLoadingSubmissions(false);
    }
  }, [allSubmissions]);

  useEffect(() => {
    if (updateTenantRes) {
      if (updateTenantRes?.status === 200) {
        if (updateTenantRes?.data?.status) {
          Swal.fire({
            text: `${currentStatus} Successfully.`,
            icon: "success",
            confirmButtonColor: "#5A7890",
            customClass: {
              title: "text-danger",
              content: "text-danger",
            },
          });
        } else {
          Swal.fire({
            text: updateTenantRes?.data?.message,
            icon: "error",
            confirmButtonColor: "#5A7890",
            customClass: {
              title: "text-danger",
              content: "text-danger",
            },
          });
        }
      }
      
      setIsModalVisible(false);
      // Reload submissions after status update
      setFilteredSubmissions(filteredSubmissions.map((submission) => {
        if (submission.id === currentSubmissionId) {
          submission.status = currentStatus === "Approved" ? "Approved" : "Rejected";
          submission.actions = "";
        }
        return submission;
      }));
    }
  }, [updateTenantRes]);

  // Columns for displaying submission data
  const columns = [
    {
      title: "Property Name",
      dataIndex: "property",
      key: "property",
    },
    {
      title: "Submission Date",
      dataIndex: "submissionDate",
      key: "submissionDate",
    },
    {
      title: "Payment",
      dataIndex: "payment",
      key: "payment",
    },
    {
      title: "Submission Type",
      dataIndex: "submissionType",
      key: "submissionType",
    },
    {
      title: "Approval",
      dataIndex: "status",
      key: "status",
    },
    {
      title: "Actions",
      key: "actions",
      render: (text, record) => (
        <div className="tenant-action-wrap">
          {/* View Details Button */}
          <Button
            style={{
              borderRadius: 19,
              border: "1px solid #fff",
              color: "#fff",
              backgroundColor: "#5A7890",
              height: "2rem",
              width: "7rem",
              marginRight: "10px",
            }}
            onClick={() =>
              navigate(`./View/${residentKey || ""}`, {
                state: { submissionId: record.id },
              })
            }
          >
            View Details
          </Button>
          {/* Show Make a Payment button if residentKey is available */}
          {residentKey && record.amount && record.payment !== "Done" && record.payment !== "Renewal" && (
            <Button
              style={{
                backgroundColor: "#5A7890",
                color: "#fff",
              }}
              onClick={() =>
                navigate(`/Forms/PaymentPage/${record?.id}`, {
                  state: { residentKey: residentKey, fee: record.amount },
                })
              }
            >
              Make a Payment
            </Button>
          )}
          {/* Show Approve and Reject buttons only for Pending submissions and if residentKey is not available */}
          {!residentKey && record.status === "Pending" && (
            <>
              <Button
                icon={<CheckOutlined />}
                style={{
                  borderRadius: 19,
                  marginRight: "10px",
                  backgroundColor: "#5A7890",
                  color: "#fff",
                  height: "2rem",
              width: "7rem",
                }}
                onClick={() => showModal(record.id, "Approved")}
              >
                Approve
              </Button>
              <Button
                icon={<CloseOutlined />}
                style={{
                  borderRadius: 19,
                  backgroundColor: "#5A7890",
                  color: "#fff",
                  height: "2rem",
              width: "7rem",
                }}
                onClick={() => showModal(record.id, "Rejected")}
              >
                Reject
              </Button>
            </>
          )}
        </div>
      ),
    },
  ];

  // Show the modal for approving/rejecting
  const showModal = (id, status) => {
    setCurrentSubmissionId(id);
    setCurrentStatus(status);
    setIsModalVisible(true);
  };

  // Handle modal submit
  const handleOk = () => {
    if (!remark.trim()) {
      alert("Please enter a remark.");
      return;
    }

    const approvalBy = user?.getUserInformation()?.displayName; 
    const req = {
      id: currentSubmissionId,
      status : currentStatus === "Approved" ? 1 : 2,
      reason: remark,
      approvalBy: approvalBy
    };
    const request = {
      request:req,
      handle:handleCancel
    };
    dispatch(Tenants.UpdateTenantRequestStatusRequest(request));
  };

  // Handle modal cancel
  const handleCancel = () => {
    setIsModalVisible(false);
    setRemark("");
  };
  useEffect(() => {
    dispatch(
      Headers.setHeader({
        title: "Tenant Registrations",
        actions: [
        ],
      })
    );
    return () => dispatch(Headers.resetHeader());
  }, [dispatch]);
  return (
    <>
      {isLoadingSubmissions && <Spinner />}
      <Card>
        <Card>
          <Select
            defaultValue="All"
            style={{ width: 200, height: 40 }}
            onChange={handleFilterSubmissions}
          >
            <Select.Option value="All">All</Select.Option>
            <Select.Option value="Approved">Approved</Select.Option>
            <Select.Option value="Rejected">Rejected</Select.Option>
            <Select.Option value="Pending">Pending</Select.Option>
          </Select>
        </Card>
        <Card>
          <Table columns={columns} dataSource={filteredSubmissions} />
        </Card>
      </Card>

      {/* Modal for remark input */}
      <Modal
        title={`Enter Remark for ${currentStatus}`}
        visible={isModalVisible}
        onOk={handleOk}
        onCancel={handleCancel}
        okText="Submit"
        cancelText="Cancel"
      >
        <Input.TextArea
          rows={4}
          value={remark}
          onChange={(e) => setRemark(e.target.value)}
          placeholder="Please enter your remark"
        />
      </Modal>
    </>
  );
};

export default Submissions;
