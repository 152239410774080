/* eslint-disable */
import { observer } from "mobx-react-lite";
import { ReactElement, useRef, useState } from "react";
import * as React from "react";
import { useStore } from "../../../../stores/root-store-context";
import { UserEmployee } from "../../../../api/base/ConfigApi";
import {
  Button,
  Col,
  DatePicker,
  Form,
  Input,
  Modal,
  Row,
  Select,
  Space,
  Spin,
  Upload,
  UploadProps,
} from "antd";
import { ContactInformation } from "../ContactInfo";
import { usStates } from "../../helpers/State";
import AddContactInfo from "../AddContactInfo";

const EditNameAddressForm = observer(
  (props: {
    contactInfo: ContactInformation;
    isOpenModal: boolean;
    dismissModal: () => void;
    contact: ContactInformation;
      updateAccount: (
          contactData: ContactInformation,
          modifiedString?: string | null
        ) => void;
        deleteAccount: (
          methodId: number,
          type: string,
          deletedValue: string
        ) => void;
     
  }): ReactElement => {
    const { ricStore, applicationStore } = useStore();
    const [form] = Form.useForm();
    const [isUpdating, setIsUpdating] = useState<boolean>(false);
    const [addContactInfo, setAddContactInfo] = useState<ContactInformation>(
      JSON.parse(JSON.stringify(props.contact))
    );
    const [errorMessage, setErrorMessage] = useState<string | null>(null);
    const { isOpenModal, dismissModal,contactInfo,updateAccount,deleteAccount } = props;
    const handleValuesChange = (changedValues: any, allValues: ContactInformation) => {
      setAddContactInfo((prev) => ({
        ...prev,
        ...allValues,
      }));
    };

    const getModifiedString = (
      oldData: ContactInformation,
      newData: ContactInformation
    ): string => {
      let newModifiedString = "";
      if (oldData.firstName !== newData.firstName) {
        newModifiedString += `First Name modified from '${
          oldData.firstName
        }' to '${newData.firstName}' for contact id '${
          newData.contactId ? newData.contactId : oldData.contactId
        }'.\n`;
      }
      if (oldData.lastName !== newData.lastName) {
        newModifiedString += `Fast Name modified from '${
          oldData.lastName
        }' to '${newData.lastName}' for contact id '${
          newData.contactId ? newData.contactId : oldData.contactId
        }'.\n`;
      }
      if (
        oldData.contactAddress?.mailingAddress1 !==
        newData.contactAddress?.mailingAddress1
      ) {
        newModifiedString += `Address modified from '${
          oldData.contactAddress?.mailingAddress1
        }' to '${newData.contactAddress?.mailingAddress1}' for contact id '${
          newData.contactId ? newData.contactId : oldData.contactId
        }', ${
          newData.contactFullName
            ? newData.contactFullName
            : oldData.contactFullName
        }.\n`;
      }
      if (
        oldData.contactAddress?.mailingAddress2 !==
        newData.contactAddress?.mailingAddress2
      ) {
        newModifiedString += `Address 2 modified from '${
          oldData.contactAddress?.mailingAddress2
        }' to '${newData.contactAddress?.mailingAddress2}' for contact id '${
          newData.contactId ? newData.contactId : oldData.contactId
        }', ${
          newData.contactFullName
            ? newData.contactFullName
            : oldData.contactFullName
        }.\n`;
      }
      if (
        oldData.contactAddress?.mailingCity !==
        newData.contactAddress?.mailingCity
      ) {
        newModifiedString += `City modified from '${
          oldData.contactAddress?.mailingCity
        }' to '${newData.contactAddress?.mailingCity}' for contact id '${
          newData.contactId ? newData.contactId : oldData.contactId
        }', ${
          newData.contactFullName
            ? newData.contactFullName
            : oldData.contactFullName
        }.\n`;
      }
      if (
        oldData.contactAddress?.mailingRegion !==
        newData.contactAddress?.mailingRegion
      ) {
        newModifiedString += `State modified from '${
          oldData.contactAddress?.mailingRegion
        }' to '${newData.contactAddress?.mailingRegion}' for contact id '${
          newData.contactId ? newData.contactId : oldData.contactId
        }', ${
          newData.contactFullName
            ? newData.contactFullName
            : oldData.contactFullName
        }.\n`;
      }
      if (
        oldData.contactAddress?.mailingPostalCode !==
        newData.contactAddress?.mailingPostalCode
      ) {
        newModifiedString += `Postal Code modified from '${
          oldData.contactAddress?.mailingPostalCode
        }' to '${newData.contactAddress?.mailingPostalCode}' for contact id '${
          newData.contactId ? newData.contactId : oldData.contactId
        }', ${
          newData.contactFullName
            ? newData.contactFullName
            : oldData.contactFullName
        }.\n`;
      }
      return newModifiedString;
    };
   
    const onFinish = async () => {
      // Log the contactPhoneLegth to inspect the data
      console.log(ricStore?.contactPhoneLegth, "addContactInfoaddContactInfoaddContactInfoaddContactInfo",addContactInfo);
    
      // Check if any phone number is empty
      const hasEmptyPhoneNumber = ricStore?.contactPhoneLegth.some(
        (phone) => phone.number === ""
      );
    
      if (hasEmptyPhoneNumber) {
        setErrorMessage("One or more phone numbers are blank. Please fill in all phone numbers.");
        return; // Prevent the API call
      }
    
      setIsUpdating(true);
      ricStore.setIsLoadingApp(true);
      console.log(addContactInfo);
    
      // Proceed with the API call if validation passed
      ricStore
        .updateNameAndAddress(
          addContactInfo,
          applicationStore.userEmployee.employeeKey,
          applicationStore.userEmployee.employeeID,
          getModifiedString(props.contact, addContactInfo)
        )
        .then(() => {
          ricStore.getRicInfo().catch(console.log);
        })
        .catch(console.log);
    
      setIsUpdating(false);
      dismissModal();
    };
    
console.log(addContactInfo,"addContactInfoaddContactInfoaddContactInfoaddContactInfo")
    return (
      <Modal
        title={"Edit Name and Address"}
        style={{ top: "5%", height: "90%", padding: 0 }}
        width={800}
        open={isOpenModal}
        footer={null}
        onCancel={() => {
          dismissModal();
        }}
      >
        <Spin spinning={isUpdating}>
          <Form
            form={form}
            variant="outlined"
            layout="vertical"
            initialValues={addContactInfo}
            labelCol={{ span: 24 }}
            style={{
              width: "100%",
              padding: 8,
            }}
           onFinish={onFinish}
            onValuesChange={handleValuesChange}
            // validateMessages={validateMessages}
            // requiredMark={customizeRequiredMark}
            autoComplete="off"
            scrollToFirstError={{ block: "center", inline: "nearest" }}
          >
            <Row>
              <Col span={24}>
                <Form.Item<ContactInformation> name="accountNumber" hidden>
                  <Input />
                </Form.Item>
                <Form.Item<ContactInformation> name="id" hidden>
                  <Input />
                </Form.Item>
                <Form.Item<ContactInformation> name="user" hidden>
                  <Input />
                </Form.Item>
                <Form.Item<ContactInformation> name="employeeKey" hidden>
                  <Input />
                </Form.Item>
              </Col>
            </Row>
            <Row>
              <Col span={12}>
                <Form.Item<ContactInformation>
                  label="First Name"
                  name="firstName"
                  style={{ margin: 5 }}
                  
                >
                  <Input disabled={props.contact.primaryOwner === "Yes"} />
                </Form.Item>
              </Col>
              <Col span={12}>
                <Form.Item<ContactInformation>
                  label="Last Name"
                  name="lastName"
                  style={{ margin: 5 }}
                >
                  <Input disabled={props.contact.primaryOwner === "Yes"} />
                </Form.Item>
              </Col>   <Col span={12}>
                <Form.Item<ContactInformation>
                  label="Address"
                  name={["contactAddress", "mailingAddress1"]}
                  style={{ margin: 5 }}
                >
                  <Input disabled={!ricStore.isEditable} />
                </Form.Item>
              </Col> <Col span={12}>
                <Form.Item<ContactInformation>
                  label="Address 2"
                  name={["contactAddress", "mailingAddress2"]}
                  style={{ margin: 5 }}
                >
                  <Input disabled={!ricStore.isEditable} />
                </Form.Item>
              </Col> <Col span={12}>
                <Form.Item<ContactInformation>
                  label="City"
                  name={["contactAddress", "mailingCity"]}
                  style={{ margin: 5 }}
                >
                  <Input disabled={!ricStore.isEditable} />
                </Form.Item>
              </Col> <Col span={12}>
                <Form.Item<ContactInformation>
                  label="State"
                  name={["contactAddress", "mailingRegion"]}
                  style={{ margin: 5 }}
                >
                  <Select
                    options={usStates.map((el, index) => {
                      return {
                        value: el.code,
                        label: `${el.code} | ${el.name}`,
                      };
                    })}
                    disabled={!ricStore.isEditable}
                  />
                </Form.Item>
              </Col>
              <Col span={12}>
                <Form.Item<ContactInformation>
                  label="Zip Code"
                  name={["contactAddress", "mailingPostalCode"]}
                  style={{ margin: 5 }}
                  rules={[{ min: 5, max: 7 }]}
                >
                  <Input disabled={!ricStore.isEditable} />
                </Form.Item>
              </Col>
            </Row>
         
          
           
            <AddContactInfo
       contactInfo={addContactInfo}
       updateAccount={(updatedContactInfo: ContactInformation,modifiedString:string) => {
         setAddContactInfo((prev) => ({
           ...prev,
           ...updatedContactInfo,
           modifiedString:modifiedString,
         }));
       }}
       errorMessage ={errorMessage }
       deleteAccount={deleteAccount}
            /> 
            <Row>
              <Col span={24} style={{ textAlign: "right", marginTop: "16px" }}>
                <Space>
                  <Button
                    size="middle"
                    onClick={() => {
                      dismissModal();
                    }}
                  >
                    Cancel
                  </Button>
                  <Button
                    size="middle"
                    type="primary"
                    htmlType="submit"
            //  onClick={onFinish}
                  >
                    Save
                  </Button>
                </Space>
              </Col>
            </Row>
          </Form>
        </Spin>
      </Modal>
    );
  }
);
export default EditNameAddressForm;
