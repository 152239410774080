/* eslint-disable */
import { takeLatest, call, put } from 'redux-saga/effects';
import Constant from '../../Constants/Constants';
import {Tenants} from '../../Actions';
import JwtAuthService from '../../../services/JwtAuthService';


  function* GetAllTenantSubmissionsList() {
    try {
        const response = yield call(JwtAuthService.getAPI,'',`api/Tenant/getAllTenantSubmissions`);
        yield put(Tenants.GetAllTenantSubmissionsSuccess(response));
    } catch (error) {
        yield put(Tenants.GetAllTenantSubmissionsFailure(error.message));
    }
    
}

function* GetTenantSubmissionDetailsById(data) {
    try {
        const response = yield call(JwtAuthService.getAPI,'',`api/Tenant/getTenantSubmissionDetailsById?id=${data?.payload?.id}`);
        yield put(Tenants.GetTenantSubmissionsByIdSuccess(response));
    } catch (error) {
        yield put(Tenants.GetTenantSubmissionsByIdFailure(error.message));
    }
    
}

function* UpdateTenantById(data) {
    try {
        const response = yield call(JwtAuthService.postApi,data?.payload?.request,`api/Tenant/updateTenantRequestStatus?id=${data?.payload?.request?.id}&status=${data?.payload?.request?.status}&reason=${data?.payload?.request?.reason}&approvalBy=${data?.payload?.request?.approvalBy}`);
        yield put(Tenants.UpdateTenantRequestStatusSuccess(response));
        data?.payload?.handleCancel(response);
       
    } catch (error) {
        yield put(Tenants.UpdateTenantRequestStatusFailure(error.message));
    }
    
}

export function* Tenant() {
  yield takeLatest(Constant.GET_ALL_TENANT_SUBMISSIONS_REQUEST, GetAllTenantSubmissionsList);
  yield takeLatest(Constant.GET_TENANT_BYID_REQUEST, GetTenantSubmissionDetailsById);
  yield takeLatest(Constant.UPDATED_TENANT_BYID_REQUEST, UpdateTenantById);
}
