/* eslint-disable */
import { observer } from "mobx-react-lite";
import { useEffect } from "react";
import { Badge, Button, Checkbox, Col, ConfigProvider, Form, Row } from "antd";
import "./ric.css";
import * as React from "react";
import { useStore } from "../stores/root-store-context";
import "../tmp.css";
import Headers from '../../../../../../redux/Actions/Headers/Header'
import DynamicTable from '../../../../../../Components/Table/Table';
import Spinner from '../../../../../../Components/Spinner/Spiner'
import { ISearchItem } from "./RIC/components/AdvancedSearch";
import { PaginationConfig } from "antd/es/pagination";
import {useDispatch} from 'react-redux';
import { useNavigate } from "react-router-dom";
import TextInput from "../../../../../../Components/TextInput/TextInput";
interface SearchData {
  searchText?: string; // Marked as optional if it might be undefined initially
  status? : boolean ;
}
interface Pagination {
  current?: number;
  pageSize?: number;
  filter?: string;
  total?: number; 
}

const RIC = observer(() => {
  const [form] = Form.useForm();
  const debounceTimer = React.useRef(null);
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { ricStore, applicationStore } = useStore();
  const [searchData , setSearchData] = React.useState<SearchData>({});
  const [loading ,  setIsLoading] = React.useState(false);
  const [pagination, setPagination] = React.useState<PaginationConfig>({
    current: 1,
    pageSize: 25,
    total: 0,
  });
  
console.log(applicationStore.PropertieList,"applicationStore.PropertieListapplicationStore.PropertieListapplicationStore.PropertieList")
  useEffect(() => {
    if ((applicationStore.SelectedProperties?.id || applicationStore.SelectedProperties?.id == 0) && (applicationStore.PropertieList?.length <= 0 || !applicationStore.PropertieList)) {
      searchData["status"] = false;
      setSearchData(searchData)
      ricStore.getFilterList(applicationStore.SelectedProperties.id);
    }
  }, [applicationStore.SelectedProperties]);
  useEffect(() => {
      ricStore.setAccount(undefined);
    },
    []
  );

  // const columns = [
  //   { title: "First Name", dataIndex: "name" },
  //   { title: "Last Name", dataIndex: "lastName" },
  //    { title: "Community", dataIndex:"community" },
  //   { title: "Email", dataIndex: "email" },
  //   { title: "Address", dataIndex: "address" },
  //   { title: "Phone", dataIndex: "phone" },
  //   // { title: "block/Lot", dataIndex: "blockLot" },
  //   { title: "Account", dataIndex: "account" },
  //   { title: "ContactKey", dataIndex: "residentContactKey" },
  //   { title: "Status", dataIndex: "status" ,render:(value:any,record:any)=>  <Badge
  //     count={value ? "Active" : "Inactive"}
  //     style={{
  //       backgroundColor: "transparent",
  //       color: value ? "green" : "red",
  //       border: `1px solid ${value ? "green" : "red"}`,
  //     }}
  //   /> },
  //   // { title: "Property", dataIndex: "community" },
  //   { title: "Actions", dataIndex: "actions",render:(value:any,record:any)=><Button onClick={()=>navigate(`/AAM/Ric/Account/${record?.account}`)}>View</Button> },
  
  // ];
  const columns = [
    { 
      title: "First Name", 
      dataIndex: "name", 
      defaultSortOrder: "ascend",
      sorter: (a, b) => a.name.localeCompare(b.name), 
      sortDirections: ['ascend', 'descend', 'ascend'],
    },
    { 
      title: "Last Name", 
      dataIndex: "lastName", 
      sorter: (a, b) => a.lastName.localeCompare(b.lastName), 
      sortDirections: ['ascend', 'descend', 'ascend'],
    },
    { 
      title: "Community", 
      dataIndex: "community", 
      sorter: (a, b) => a.community.localeCompare(b.community),
      sortDirections: ['ascend', 'descend', 'ascend'], 
    },
    { 
      title: "Email", 
      dataIndex: "email", 
      sorter: (a, b) => a.email.localeCompare(b.email), 
      sortDirections: ['ascend', 'descend', 'ascend'],
    },
    { 
      title: "Address", 
      dataIndex: "address", 
      sorter: (a, b) => a.address.localeCompare(b.address), 
      sortDirections: ['ascend', 'descend', 'ascend'],
    },
    { 
      title: "Phone", 
      dataIndex: "phone", 
      sorter: (a, b) => a.phone.localeCompare(b.phone), 
      sortDirections: ['ascend', 'descend', 'ascend'],
    },
    { 
      title: "Account", 
      dataIndex: "account", 
      sorter: (a, b) => (a.account || "").toString().localeCompare((b.account || "").toString()), 
      sortDirections: ['ascend', 'descend', 'ascend'],
    },
    { 
      title: "ContactKey", 
      dataIndex: "residentContactKey", 
      sorter: (a, b) => (a.residentContactKey || "").toString().localeCompare((b.residentContactKey || "").toString()), 
      sortDirections: ['ascend', 'descend', 'ascend'],
    },
    { 
      title: "Status", 
      dataIndex: "status", 
      render: (value, record) => (
        <Badge
          count={value ? "Active" : "Inactive"}
          style={{
            backgroundColor: "transparent",
            color: value ? "green" : "red",
            border: `1px solid ${value ? "green" : "red"}`,
          }}
        />
      ),
    },
    { 
      title: "Actions", 
      dataIndex: "actions", 
      render: (value, record) => (
        <Button onClick={() => navigate(`/AAM/Ric/Account/${record?.account}`)}>View</Button>
      ),
    },
  ];
  
  const HandleStatus = (e:any)=>{
     setSearchData[e?.target?.name] = e?.target?.checked;
     form.setFieldsValue(searchData)
     if (debounceTimer.current) {
      clearTimeout(debounceTimer.current);
    }
  
    debounceTimer.current = setTimeout(() => {
      OnSearch('','',searchData?.searchText,e?.target?.checked, pagination.pageSize || 10, 1); 
    }, 1000);
  }
  const HandleOnChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { value, name } = e.target;
    setSearchData((prev) => ({ ...prev, [name]: value }));
    applicationStore.setSearchValue(value)
      form.setFieldsValue(searchData)
    if (debounceTimer.current) {
      clearTimeout(debounceTimer.current);
    }
  
    debounceTimer.current = setTimeout(() => {
      setPagination({
        current: 1,
        pageSize: 25,
        total: 0,
      });
      OnSearch('','',value,searchData?.status, 25, 1); 
    }, 1000);
  };
  const handleTableChange = (newPagination: PaginationConfig, filters:any, sorter:any) => {
    const { order, field } = sorter;
    const sortOrder = order === "ascend" ? "ASC" : order === "descend" ? "DESC" : null;
  
    if (sortOrder && field) {
      setPagination(newPagination); 
       OnSearch(field,sortOrder,searchData?.searchText || "",searchData?.status, newPagination.pageSize || 10, newPagination.current || 1);
    } else {
      setPagination(newPagination); 
      OnSearch(field,sortOrder,searchData?.searchText || "",searchData?.status, newPagination.pageSize || 10, newPagination.current || 1);
    }
  }
  // const handleTableChange = (newPagination: PaginationConfig) => {
  //   setPagination(newPagination); 
  //   OnSearch(searchData?.searchText || "",searchData?.status, newPagination.pageSize || 10, newPagination.current || 1);
  // };
//   useEffect(()=>{

// if((applicationStore.SelectedProperties?.id || applicationStore.SelectedProperties?.id == 0) && (ricStore.accountList?.length <= 0 || !ricStore.accountList)){
//     OnSearch(searchData?.searchText,searchData?.status, pagination.pageSize || 10, 1); 

// }
//   },[applicationStore.SelectedProperties?.id])
  
  const OnSearch = (field:string,sortOrder:string,value: string,status:boolean, pageSize: number, pageNumber: number) => {
    const req = {
      searchText: value ?? '',
      n_propertyID: applicationStore.SelectedProperties?.id === 0 ? null : applicationStore.SelectedProperties?.id,
      includePreOwners: false,
      pageNumber: pageNumber,
      rowsPerPage: pageSize,
      status:status ? false : true,
      sort:field ? field.charAt(0).toUpperCase() + field.slice(1) : null,
      sortDirection:sortOrder ? sortOrder :  null
    };
    setIsLoading(true);
    ricStore
      .searchInformation(req)
      .then((res) => {
        ricStore.setAccountList(res?.data)
        ricStore.setAccountListCount(res?.data?.length > 0 ? res?.data[0]?.totalCount : 0)
        setPagination((prev) => ({
          ...prev,
          current: pageNumber,
          pageSize: pageSize,
          total: res?.data?.length > 0 ? res?.data[0]?.totalCount : 0,
        }));
        setIsLoading(false);
      })
      .catch((error) => {
        setIsLoading(false);
        console.error("Error fetching search results:", error);
      });
  };
  useEffect(()=>{
    if(ricStore.accountCount){
      setPagination((prev) => ({
        ...prev,
        current: pagination?.current,
        pageSize: pagination?.pageSize,
        total: ricStore.accountCount ?? 0,
      }));
    }
  },[ricStore.accountCount])
  // const HandleOnChange = (e:any) => {
  
  //     const { value, name } = e?.target;
  //     console.log(value, name,"eeeee")
  //     searchData[name] = value;
  //     setSearchData(searchData);
  //     if (debounceTimer.current) {
  //       clearTimeout(debounceTimer.current);
  //   }
  //   debounceTimer.current = setTimeout(() => {
  //     OnSearch(value,pagination.pageSize,pagination.current);
  //   }, 1000);
 
  //  };
  //  const handleTableChange = (pagination: PaginationConfig) => {
  //   OnSearch(searchData?.searchText || "", pagination.pageSize || 10, pagination.current || 1);
  //   setPagination(pagination);
  // };
  //  const OnSearch = (value:string,pageSize:number,pageNumber:number)=>{
  //   const req = {
  //     searchText: value,
  //     n_propertyID:applicationStore.SelectedProperties?.id === 1 ? null : applicationStore.SelectedProperties?.id,
  //     includePreOwners: true, 
  //     pageNumber:pageNumber, 
  //     rowsPerPage: pageSize,
  //   }
  //  const res = ricStore.searchInformation(req).then((res) => {
  //   // const filteredSearchItems = filterItems(
  //   //   res.data,
  //   //   applicationStore.PropertieList.map((el) => el.id)
  //   // );
  //   // const items = filterSearchItems(
  //   //   filteredSearchItems,
  //   //   mergeWithDefault(itemSearch)
  //   // );
  //   console.log(res?.data,"res?.datares?.datares?.datares?.data")
  //   setPagination({
  //     ...pagination,
  //     total:
  //    res?.data?.totalCount
  //     ,
  //   });
  //  // setIsLoading(false);
  // })
  // .catch((error: {}) => {
  //   //setIsLoading(false);
  //   console.log(error);
  // });
  //  }
  const onRow = (record:any) => ({
    onClick: () => {
      navigate(`/AAM/Ric/Account/${record?.account}`);
    },
  })
  console.log(ricStore.accountList,"accountListaccountListaccountListaccountListaccountListaccountListaccountList")
  useEffect(() => {
    dispatch(
      Headers.setHeader({
        title: "RIC-Resident Information Console",
        actions: [
          {
            type: "property",
            onClick:() =>OnSearch('name','ascend',searchData?.searchText,searchData?.status, pagination.pageSize || 10, 1)
          },
        ],
      })
    );
    return () => {
      dispatch(Headers.resetHeader());
    };
  }, [dispatch]);
  useEffect(()=>{
    // if(applicationStore?.searchValue){
     searchData['searchText'] = applicationStore?.searchValue;
      setSearchData(searchData)
     form.setFieldsValue(searchData)
    // }
  },[applicationStore?.searchValue])

  return (
    <div className="ric">
      {(loading || applicationStore.isLoading) && <Spinner/>}
      <ConfigProvider
        key="RicConfigProvider"
        theme={{
          token: {},
          components: {
            Tabs: {
              cardBg: "#5a7890",
              colorBgContainer: "#dedede",
              itemColor: "#ffffff",
              itemSelectedColor: "#000000",
              itemHoverColor: "#000000",
              itemActiveColor: "#000000",
            },
            Button: {
              primaryColor: "#ffffff",
              defaultColor: "#ffffff",
              colorBgContainer: "#5a7890",
              controlHeight: 38,
              borderRadius: 10,

              defaultHoverBg: "#dedede",
              defaultHoverColor: "#000000",
              defaultBorderColor: "#dedede",
              defaultActiveColor: "#ffffff",
              defaultHoverBorderColor: "#5a7890",
            },
            Table: {
              rowHoverBg: "rgb(218, 221, 225)",
            },
          },
        }}
      >
        <div >
          {/* <Flex justify="space-between" wrap="wrap" align="center" gap="middle" style={{ padding: 10 }}>
                    <div style={{ display: "flex", width: "100%", flexWrap: "wrap", justifyContent: "space-between" }}>
                        <Space style={{ flexWrap: "wrap" }}>
                            <h2 style={{ width: "280px" }}>RIC (Resident Info)</h2>
                        </Space>
                    </div>
                </Flex> */}
         <Row justify={'end'} gutter={[16,16]} align={'middle'}>
         
          <Col sm={8}>
          <Form
  layout="vertical"
  className="ant-form"
  labelCol={{ span: 24 }}
  wrapperCol={{ span: 24 }}
  form={form}
  initialValues={{status:false}}
>
  <Row align={'middle'}>
    <Col sm={12}>
    <Checkbox
      name="status"
      onChange={(e) =>HandleStatus(e)}
    >
     Show In-Actives 
    </Checkbox></Col>
   <Col sm={12}>
   <TextInput
      name="searchText"
      placeholder="Search"
      className="search"
      icon={
        <svg
          width="16"
          height="16"
          viewBox="0 0 16 16"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M14 14L11.1 11.1M12.6667 7.33333C12.6667 10.2789 10.2789 12.6667 7.33333 12.6667C4.38781 12.6667 2 10.2789 2 7.33333C2 4.38781 4.38781 2 7.33333 2C10.2789 2 12.6667 4.38781 12.6667 7.33333Z"
            stroke="#757575"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
        </svg>
      }
      onChange={HandleOnChange}
    /></Col>
   
  </Row>
</Form>

          </Col>
          <Col sm={24}>
          <DynamicTable onRow={onRow} data={ricStore.accountList ?? []} columns={columns} pagination={{
            current: pagination.current,
            pageSize: pagination.pageSize,
            total: pagination.total,
            showSizeChanger: true,
          }}  
           onChange={handleTableChange}
          
          /> </Col>
         </Row>
          {/* {applicationStore.SelectedProperties?.id === 0 ? ( */}
            <>
            {/* <AllSearch /> */}
          
           
            </>
          {/* ) : (
            <></>
          )} */}
          {/* {applicationStore.SelectedProperties?.id > 0 ? (
            <Spin spinning={ricStore.isLoadingApp || ricStore.isLoadingRicData}>
              <SearchForm
                item={ricStore.filterList}
                selectedAccount={ricStore?.selectedAccount}
                selectCallBack={(item) => {
                  ricStore.setAccount(item);
                }}
                resetCallBack={() => {
                  ricStore.setAccount(undefined);
                  applicationStore.setSearchTextProperty("");
                  applicationStore.setSelectedProperties({} as Properties);
                }}
              />
            </Spin>
          ) : (
            <></>
          )} */}
          {/* {ricStore.isShowRicInfo === true ? (
            <div>
              <Row>
                <Col span={24}>
                  <AccountInfoBlock />
                </Col>
              </Row>
              <Row>
                <Col span={24}>
                  <TableAccountInfo />
                </Col>
              </Row>
            </div>
          ) : (
            <></>
          )} */}
        
        </div>
      </ConfigProvider>
    </div>
  );
});

export default RIC;
