import { CopyOutlined } from "@ant-design/icons";
import {
  Button,
  Col,
  Form,
  Row,
  Switch,
  Table,
} from "antd";
import Component from "Components";
import { useDispatch, useSelector } from "react-redux";
import { useEffect, useState } from "react";
import { Community, Users } from "../../../redux/Actions";
import { useParams } from "react-router-dom";

const { DynamicTable, SelectBox, Modal, Spinner, CheckboxTable } = Component;
const CommunityPermission = () => {
  const { id } = useParams();
  const [form] = Form.useForm();
  const dispatch = useDispatch();
  const [userData, setUserData] = useState({});
  const [viewCommunity, setViewCommunity] = useState(false);
  const [communityId, setCommunityId] = useState("");
  const [selectedUnitRowKeys, setSelectedUnitRowKeys] = useState([]);
  const [selectedCommunity, setSelectedCommunity] = useState([]);
  const {  GetFeatures, loading } = useSelector(
    (state) => state?.GetAllUser
  );
  const {getAllCommunitys    } = useSelector((state) => state?.Communitys);
  const [featureList, setFeatureList] = useState([]);
  const HandleUpdate = () => {
    const groupedData = featureList.reduce((acc, current) => {
      const { communityIds, featureIds, isActiveFeature } = current;

      if (isActiveFeature) {
        const existingCommunity = acc.find(
          (item) => item.communityId === communityIds
        );

        if (existingCommunity) {
          existingCommunity.featureIds.push(featureIds);
        } else {
          acc.push({
            communityId: communityIds,
            featureIds: [featureIds],
          });
        }
      }

      return acc;
    }, []);

    let req = {
      request: groupedData,
    };
    dispatch(Users.SetFeaturesByCommunityIdRequest(req));
  };

  const handleSwitchChange = async (id, checked) => {
    try {
      setFeatureList((prevData) =>
        prevData.map((item) => {
          return item?.featureIds === id?.featureIds &&
            item?.communityIds === id?.communityIds
            ? { ...item, isActiveFeature: checked }
            : item;
        })
      );
    } catch (error) {
      console.error("Error updating status:", error);
    }
  };

  useEffect(() => {
    const data = [];

    if (GetFeatures?.data?.length > 0) {
      for (let items of GetFeatures?.data ?? []) {
        const communityId = items?.communityId;

        for (let i = 0; i < items?.features?.length; i++) {
          const isActiveFeature =
            items?.features[i]?.isActiveFeature == 1 ? true : false;
          const featureId = items?.features[i]?.featureId;
          const feature = items?.features[i]?.feature;
          const parentNode = items?.features[i]?.parentNode;
          const exceptionText = items?.features[i]?.exceptionText;

          const existingEntry = data.find(
            (entry) =>
              entry.communityIds === communityId &&
              entry.featureIds === featureId
          );

          if (!existingEntry) {
            data.push({
              key: `${communityId}-${featureId}`,
              date: feature,
              isActiveFeature: isActiveFeature,
              featureIds: featureId,
              communityIds: communityId,
              parentNode: parentNode,
              exceptionText:exceptionText
            });
          }
        }
      }

      setFeatureList(data);
    }
  }, [GetFeatures]);


  useEffect(() => {
    dispatch(Users.GetUsersRequest());
    dispatch(Community.GetAllCommunitysRequest());
  }, []);


  const onSearch = (value) => {
   console.log(value)
  };

  const filterOption = (input, option) =>
    (option?.children ?? "").toLowerCase().includes(input.toLowerCase());

  useEffect(() => {
    if (id) {
      userData["Community"] = id;
      setUserData(userData);
      form.setFieldsValue(userData);
    }
    if (!id) {
      userData["Users"] = "";
      userData["Community"] = undefined;
      setUserData(userData);
      form.setFieldsValue(userData);
    }
  }, [id]);

  const HandleOnChange = (e) => {
    const req = {
      userId: e?.target?.value,
    };
    userData[e?.target?.id] = e?.target?.value;
    setUserData(userData);
    if (e?.target?.id == "Users") {
      userData["Community"] = undefined;
      setUserData(userData);
      form.setFieldsValue(userData);
      dispatch(Users.GetCommunityByUserIdRequest({ request: req }));
    }
    if (e?.target?.id == "Community") {
      dispatch(Users.GetFeaturesByCommunityIdRequest({ request: req?.userId }));
    }
  };

  const createNewFeatures = (originalFeatures, selectedCommunities) => {
    const newFeatures = [];

    selectedCommunities.forEach((community) => {
      originalFeatures.forEach((feature) => {
        if (feature.communityIds == communityId) {
          const newFeature = {
            ...feature, 
            key: feature.key.replace(
              JSON.stringify(communityId),
              community.value
            ), 
            communityIds: community.value, 
          };
          newFeatures.push(newFeature); 
        }
      });
    });

    return newFeatures;
  };

  useEffect(() => {
    if (featureList?.length > 0 && selectedCommunity?.length > 0) {
      const newFeatureList = createNewFeatures(featureList, selectedCommunity);
      const mergedFeatureList = [...featureList, ...newFeatureList];
      const uniqueFeatures = Array.from(
        new Map(mergedFeatureList.map((item) => [item.key, item])).values()
      );
      setFeatureList(uniqueFeatures);
    }
  }, [selectedCommunity]);

  const expandedRowRender = (record) => {
    const columns = [
      {
        title: "Features Name",
        dataIndex: "date",
        key: "date",
        width: 300,
      },
      {
        title: "Exception Text",
        dataIndex: "exceptionText",
        key: "exceptionText",
        width: 300,
      },
      {
        title: "Permissions",
        key: "isActiveFeature",
        dataIndex: "isActiveFeature",
        width: 150,
        render: (isActiveFeature, record) => (
          <Switch
            checked={isActiveFeature}
            onChange={(checked) => handleSwitchChange(record, checked)}
            checkedChildren="Active"
            unCheckedChildren="Inactive"
          />
        ),
      },
    ];

    const filteredData = featureList.filter(
      (item) => item.communityIds === record.value
    );

    const groupedFeatures = filteredData.reduce((acc, curr) => {
      const parentKey = curr.parentNode || "No Parent Node";
      if (!acc[parentKey]) acc[parentKey] = [];
      acc[parentKey].push(curr);
      return acc;
    }, {});
   
    const dataSource = Object.keys(groupedFeatures).map(
      (parentNode, index) => ({
        key: `${record.value}-${index}`,
        parentNode: parentNode,
        features: groupedFeatures[parentNode],
      })
    );
    return dataSource.map((group) => (
      <>
        
        <Row justify={'center'}>
          <Col sm={20} style={{marginTop:"20px"}}>
          
          <Table
          columns={columns}
          dataSource={group.features}
          pagination={false}
          title={()=><h3 >
            {group.parentNode}
          </h3>}
          
        />
          </Col>
        </Row>
      </>
    ));
  };

  const columns = [
    {
      title: "Community Name",
      dataIndex: "communityName",
      key: "communityName",
    },
    {
      title: "Actions",
      key: "operation",
      render: (value, record) => (
        <Button type="none" onClick={() => viewsCommunityHandle(record)} icon={<CopyOutlined className="copy"/>}></Button>
      ),
    },
  ];

  const data = [];

  for (let i = 0; i < GetFeatures?.data?.length; ++i) {
    data.push({
      key: i.toString(),
      communityName: GetFeatures?.data[i]?.communityName,
      value: GetFeatures?.data[i]?.communityId,
    });
  }

  const viewsCommunityHandle = (user) => {
    setCommunityId(user?.value);

    setViewCommunity(true);
  };

  const start = () => {
    setTimeout(() => {
      setSelectedUnitRowKeys([]);
    }, 1000);
  };
  const onSelectUnitChange = (newSelectedRowKeys, record) => {
    setSelectedUnitRowKeys(newSelectedRowKeys);
    setSelectedCommunity(record);
  };
  const unitRowSelection = {
    selectedRowKeys: selectedUnitRowKeys,
    onChange: onSelectUnitChange,
  };

  const viewsCommunityHandleCancel = () => {
    setViewCommunity(false);
  };
  const featurecolumns = [
    {
      title: "Community Name",
      dataIndex: "communityName",
      key: "communityName",
    },
  ];
  const CommunityList = () => {
    return (
      <>
        <Row justify={"space-between"}>
          <CheckboxTable
            columns={featurecolumns}
            className="community-table"
            rowSelection={unitRowSelection}
            renderButton={true}
            hasSelected={8}
            start={start}
            data={data?.filter((data) => data?.value !== communityId)}
            pagination={false}
          />
         
        </Row>
        <Button className="create-button create-user" onClick={()=>viewsCommunityHandleCancel()} > Copy</Button>
      </>
    );
  };

  return (
    <>
      <Form
        layout="vertical"
        className="ant-form"
        labelCol={{
          span: 24,
        }}
        wrapperCol={{
          span: 24,
        }}
        initialValues={{}}
        form={form}
      >
        <Row
          gutter={[12, 12]}
          style={{ background: "white", paddingTop: "15px" }}
        >
          
          <Col sm={3}>
            <SelectBox
              options={getAllCommunitys?.data ?? []}
              className="user-form-input"
              name="Community"
              placeholder="Community"
              showSearch={true}
              onSearch={onSearch}
              filterOption={filterOption}
              size="large"
              mode={"multiple"}
              onChange={HandleOnChange}
            />
          </Col>
         
          {userData?.Community?.length > 0 && <Col sm={24}>
            <DynamicTable
              columns={columns}
              pagination={false}
              expandable={{
                expandedRowRender,
                defaultExpandedRowKeys: ["0"],
              }}
              data={data}
            />
            <div className="feature-button">
              <Button onClick={HandleUpdate}>Done</Button>
            </div>
          </Col>}
          
        </Row>
      </Form>
      {loading && <Spinner />}
      <Modal
        title="Copy permissions from “Community One”."
        centered="center"
        open={viewCommunity}
        onOk={viewsCommunityHandle}
        onCancel={viewsCommunityHandleCancel}
        Cancel={viewsCommunityHandleCancel}
        width={500} 
        bodyStyle={{ maxHeight: "500px", overflowY: "auto" }}
        loading={loading}
      >{CommunityList}</Modal>
    </>
  );
};

export default CommunityPermission;
