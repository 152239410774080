import { useNavigate } from 'react-router-dom';
import UserData from '../utils/UserData';
import { useIsAuthenticated } from '@azure/msal-react';
function PrivateRoute({ element, requiredRoles }) {
  const navigate = useNavigate();
  const {getUserLoginToken} = UserData()
  const isAuthenticated = getUserLoginToken();
  const istoken = useIsAuthenticated() ;
  console.log(requiredRoles,"isAuthenticated isAuthenticated",isAuthenticated)
  if (!isAuthenticated || !istoken) {
    return navigate('/login');
  }

  if (requiredRoles && !(requiredRoles.includes("admin") || requiredRoles.includes("CAM"))) {
    return navigate("/unauthorized");
  }

  return element;
}

export default PrivateRoute;
