/* eslint-disable */
/* eslint-disable @typescript-eslint/no-floating-promises */
import { observer } from "mobx-react-lite";
import React, { ReactElement, useEffect, useState } from "react";
import { useStore } from "../../../stores/root-store-context";
import {
  Button,
  Checkbox,
  CheckboxProps,
  Col,
  Flex,
  Input,
  Modal,
  Row,
  Spin,
  Typography, notification, NotificationArgsProps
} from "antd";
import { ContactInformation } from "./ContactInfo";
import AddContactInfo from "./AddContactInfo";
import { AddContactInfoResponse, TypeAddModal } from "../../../stores/RicStore";
type NotificationType = 'success' | 'info' | 'warning' | 'error';
const AddContactInfoModal = observer(
  (props: { text: string; type: TypeAddModal }): ReactElement => {
    const { ricStore, applicationStore } = useStore();

    const { Text } = Typography;
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [isLoading, setIsLoading] = useState(false);
    const [isErrorFirstName, setIsErrorFirstName] = useState(false);
    const [isErrorLastName, setIsErrorLastName] = useState(false);
    const [api, contextHolder] = notification.useNotification();
    const initData = (): ContactInformation => {
      return {
        accountNumber: ricStore.accountInformation?.accountNumber,
        user: applicationStore?.userEmployee?.employeeID,
        employeeKey: parseInt(applicationStore?.userEmployee?.employeeKey),
        activeFutures: null,
        activeRenters: null,
        address: "",
        address2: "",
        birthdate: null,
        businessPhone: null,
        businessPhoneMethodId: null,
        contactAddress: null,
        contactFullName: "",
        contactId: "",
        dateReceived: null,
        email: null,
        emailMethodId: null,
        expirationDate: null,
        firstName: "",
        futures: null,
        homePhone: null,
        homePhoneMethodId: null,
        id: "",
        isETrakType: false,
        isMain: false,
        lastName: "",
        mainAcc: null,
        mobilePhone: null,
        mobilePhoneMethodId: null,
        modifiedString: null,
        noActiveFutures: null,
        noActiveRenters: null,
        note: null,
        occupancy: false,
        owner: false,
        phones: [],
        primaryOwner: "",
        rc_Date5: "",
        renters: null,
        salutation: "",
        type: "",
        writeAccess: false,
      } as ContactInformation;
    };

    const [localContactInfo, setLocalContactInfo] =
      useState<ContactInformation>(initData());

    const clearData = (): void => {
      setLocalContactInfo(initData());
    };

    const showModal = (): void => {
      clearData();
      setIsModalOpen(true);
    };
    const openNotification = (type: NotificationType, description: string) => {
      return api[type]({
        message: 'Information',
        description: description
      });
    };
    const handleOk = (): void => {
      setIsLoading(true);
      if (
        localContactInfo.firstName.length === 0 ||
        localContactInfo.lastName.length === 0
      ) {
        if (localContactInfo.firstName.length === 0) {
          setIsErrorFirstName(true);
        }
        if (localContactInfo.lastName.length === 0) {
          setIsErrorLastName(true);
        }
        setIsLoading(false);
        return;
      }
      ricStore
        .getUnitInfoForAddContact()
        .then((res) => {
          let isWith =
            localContactInfo.phones !== null ||
              localContactInfo.email !== null ||
              localContactInfo.birthdate !== null ||
              localContactInfo.dateReceived !== null ||
              localContactInfo.expirationDate !== null ||
              localContactInfo.note !== null
              ? true
              : false;
          let phonesStr = "";
          if (localContactInfo.phones !== null) {
            phonesStr =
              localContactInfo.phones.length > 1 ? " Phones : " : " Phone : ";
            localContactInfo.phones.forEach((i: any) => {
              phonesStr = phonesStr + `'${i.number} - ${i.phoneType}' `;
            });
          }

          let modifiedString =
            `Created contact for ${localContactInfo.firstName} ${localContactInfo.lastName}` +
            `${isWith ? ` with` : ``}` +
            `${localContactInfo.email !== null
              ? ` email of  '${localContactInfo.email}'`
              : ``
            }` +
            `${localContactInfo.birthdate !== null
              ? ` birthdate of  '${localContactInfo.birthdate}'`
              : ``
            }` +
            `${localContactInfo.dateReceived !== null
              ? ` dateReceived of  '${localContactInfo.dateReceived}'`
              : ``
            }` +
            `${localContactInfo.expirationDate !== null
              ? ` expirationDate of  '${localContactInfo.expirationDate}'`
              : ``
            }` +
            `${localContactInfo.note !== null
              ? ` note of  '${localContactInfo.note}'`
              : ``
            }` +
            phonesStr;

          const data: AddContactInfoResponse = {
            AccountNumber: localContactInfo.accountNumber,
            User: localContactInfo.user,
            Email: localContactInfo.email,
            LastName: localContactInfo.lastName,
            FirstName: localContactInfo.firstName,
            ExpirationDate: localContactInfo.expirationDate,
            DateReceived: localContactInfo.dateReceived,
            Birthdate: localContactInfo.birthdate,
            Note: localContactInfo.note,
            Occupancy: localContactInfo.occupancy,
            EmployeeKey: localContactInfo.employeeKey,
            Phones: localContactInfo.phones,
            ModifiedString: modifiedString,
            ContactAddress: {
              ReferenceID: "",
              MailingName: `${localContactInfo.firstName} ${localContactInfo.lastName}`,
              MailingName2: "",
              MailingSalutation: "",
              MailingAddress1: `${res.streetNumber} ${res.streetName}`,
              MailingAddress2: "",
              MailingCity: res.city,
              MailingRegion: res.region,
              MailingCountry: res.country,
              MailingPostalCode: res.postalCode,
              UnitKey: res.unitKey,
            },
          };
          console.log(data);
          ricStore
            .AddContact(data, props.type)
            .then((res) => {
              console.log(res);
              if (!res) {
                //todo error
              }
              setTimeout(() => {
                ricStore
                  .getContactInformation(
                    data.AccountNumber || ""
                  )
                  .then((res) => {
                    api['success']({
                      message: 'Information',
                      description: 'Renter account added successfully.'
                    });
                    if (applicationStore.SelectedProperties?.id) {
                      ricStore.getFilterList(applicationStore.SelectedProperties.id);
                    }
                    clearData();
                    setTimeout(() => {
                      setIsLoading(false);
                      ricStore.getRicInfo().catch(console.log);
                      setIsModalOpen(false);
                    }, 1000);
                  })
                  .catch((err) => {
                    console.error(err);
                    clearData();
                    setIsLoading(false);
                    openNotification('warning', err?.message);

                  });
              }, 100);
            })
            .catch((err) => {
              console.error(err);
              clearData();
              setIsLoading(false);
              setIsModalOpen(false);
            });
        })
        .catch((err) => {
          console.error(err);
          clearData();
          setIsLoading(false);
          setIsModalOpen(false);
        });
    };

    const handleCancel = (): void => {
      clearData();
      setIsModalOpen(false);
    };

    const onChange: CheckboxProps["onChange"] = (e): void => {
      setLocalContactInfo({
        ...localContactInfo,
        occupancy: e.target.checked,
      });
    };

    const deleteAccountContactInfo = (
      methodId: number,
      type: string,
      deletedValue: string
    ): void => {
      console.log(methodId);
      console.log(type);
      console.log(deletedValue);
    };

    return (
      <>
        {contextHolder}
        <Button block type="primary" onClick={showModal}>
          {props.text}
        </Button>
        <Modal
          title="Add Information"
          open={isModalOpen}
          onOk={handleOk}
          onCancel={handleCancel}
          width={500}
          maskClosable={false}
          closable={false}
        >
          <>
            <Spin spinning={isLoading}>
              <Row gutter={[16, 16]}>
                <Col span={24}>
                  <Flex gap="middle">
                    <Flex vertical>
                      <Text type="secondary">First Name*</Text>
                      <Input
                        placeholder="First Name"
                        size="middle"
                        value={localContactInfo.firstName}
                        onChange={(e) => {
                          const newData: ContactInformation = {
                            ...localContactInfo,
                            firstName: e.target.value,
                          };
                          setIsErrorFirstName(e.target.value.length === 0);
                          setLocalContactInfo(newData);
                        }}
                        status={isErrorFirstName ? "error" : ""}
                      />
                      {isErrorFirstName === true ? (
                        <Text type="danger">First Name is required</Text>
                      ) : (
                        <></>
                      )}
                    </Flex>
                    <Flex vertical>
                      <Text type="secondary">Last Name*</Text>
                      <Input
                        placeholder="Last Name"
                        size="middle"
                        value={localContactInfo.lastName}
                        onChange={(e) => {
                          const newData: ContactInformation = {
                            ...localContactInfo,
                            lastName: e.target.value,
                          };
                          setIsErrorLastName(e.target.value.length === 0);
                          setLocalContactInfo(newData);
                        }}
                        status={isErrorLastName ? "error" : ""}
                      />
                      {isErrorLastName === true ? (
                        <Text type="danger">Last Name is required</Text>
                      ) : (
                        <></>
                      )}
                    </Flex>
                  </Flex>
                </Col>
                <Col span={24}>
                  <Checkbox
                    checked={localContactInfo.occupancy === true}
                    onChange={onChange}
                  >
                    Occupancy Verified
                  </Checkbox>
                </Col>
                <Col span={24}>
                  {localContactInfo ? (
                    <AddContactInfo
                      contactInfo={localContactInfo}
                      updateAccount={(data): void => {
                        setLocalContactInfo({
                          ...data,
                          firstName: localContactInfo.firstName,
                          lastName: localContactInfo.lastName,
                          occupancy: localContactInfo.occupancy,
                        });
                      }}
                      deleteAccount={deleteAccountContactInfo}
                      isNonPopconfirm={true}
                    />
                  ) : (
                    <></>
                  )}
                </Col>
              </Row>
            </Spin>
          </>
        </Modal>
      </>
    );
  }
);

export default AddContactInfoModal;
