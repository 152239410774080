/* eslint-disable */
import '../src/assests/styles/styles.scss'
import { RouterProvider } from "react-router-dom";
import AppRoutes from "Routes/routesConfig";
import { RootStoreContext } from "./Views/App-Views/RIC/webparts/ric/stores/root-store-context";
import UserData from "./utils/UserData";
import PrefixPath from "./config/AppConfig";
import RootStore from "./Views/App-Views/RIC/webparts/ric/stores/root-store";
import { useEffect, useState } from 'react';

function App() {
  const [userEmail, setUserEmail] = useState("");
  const user = UserData();
  const fetchUserEmail = async () => {
    return user?.getUserInformation()?.displayName;
  };

  const [isEditable] = useState(true); 
  const [domain, setDomain] = useState(PrefixPath.RIC_API_BASE_URL); 
  const [xApiKey, setXApiKey] = useState(PrefixPath.API_KEY); 

  // Load user email when component mounts
  useEffect(() => {
    const getUserEmail = async () => {
      const email = await fetchUserEmail();
      setUserEmail(email);
    };
    getUserEmail();
  }, []);

  // Configuration object for the app
  const config = {
    domain,
    userEmail:user?.getUserInformation()?.displayName,
    xApiKey,
  };

  return (
    <div>
       
       <RootStoreContext.Provider value={new RootStore(config, isEditable)}>
       <RouterProvider router={AppRoutes} /></RootStoreContext.Provider>
    </div>
  );
}

export default App;
