import React from 'react';
import { Button, Col, Form, Row, Typography } from 'antd';
// import { useLocation } from 'react-router-dom';
// import DynamicBreadcrumb from './BreadCrumb';
// import pathToLabelMap from './pathToLabelMap';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';
import TextInput from '../TextInput/TextInput';
import SelectBox from '../SelectBox/SelectBox';
import SelectProperty from '../../Views/App-Views/RIC/webparts/ric/components/RIC/components/Search/SelectPropertys';
const { Title } = Typography;

const MainLayout = ({ children }) => {
  // const location = useLocation();
  const [form] = Form.useForm();
  const { title, actions } = useSelector((state) => state.Headers);
  return (
    <div className='main-layout' >
      <Row justify={'space-between'} align={'stretch'}>
        <Col sm={18}>
        <Title level={2} className='text'>{title}</Title>
        </Col>
        <Col sm={6} style={{textAlign:"right"}}>
        {actions.map((action, index) => {
        if (action.type === "button") {
          return (
            <Button
              key={index}
              type={action.buttonType || "default"}
              onClick={action.onClick}
            >
              {action.label}
            </Button>
          );
        }
        if (action.type === "input") {
          return (
            <Form
            key={index}
            layout="vertical"
            className="ant-form"
            labelCol={{ span: 24 }}
            wrapperCol={{ span: 24 }}
            form={form}
          >
            <TextInput  name={action.name} value={action?.value}
            placeholder={action?.placeholder}  className="search" icon={action?.icon
             }  onChange={action?.onClick}/></Form>
          );
        }
        if (action.type === "select") {
          return (
            <Form
            key={index}
            layout="vertical"
            className="ant-form"
            // labelCol={{ span: 24 }}
            // wrapperCol={{ span: 24 }}
            form={form}
          >
            <SelectBox  name={action?.name} options={action?.options}  value={action?.value}
            placeholder={action?.placeholder}  className="search"  onChange={action?.onClick}/></Form>
          );
        }
        if (action.type === "property") {
          return (
            <SelectProperty onChange={action?.onClick}   key={index}/>
          );
        }
        
        if (action.type === "div") {
          return <div key={index}>{action.content}</div>;
        }
        return null;
      })}
        {children}
        </Col>
      </Row>
      
    </div>
  );
};
MainLayout.propTypes = {
  children: PropTypes.node,
};
export default MainLayout;
