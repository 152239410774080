
export const ROLES = {
    ADMIN: 'admin',
    BOARD_ADMIN: 'Board Admin',
    USER: 'CAM',
  };
  
  export const ROLE_HIERARCHY = {
    [ROLES.ADMIN]: ['admin', 'Board Admin', 'User'],
    [ROLES.BOARD_ADMIN]: ['Board Admin'],
    [ROLES.USER]: ['CAM'],
  };
  